import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    userChangeDefaultLanguage,
    userChangePassword,
    userGetActiveMerchant,
    userGetMerchants,
    userGetSettings,
    userSelectDisputeNotificationType,
    userSelectStatementNotificationType,
    userSetMerchant,
} from "redux/actions/userActions";
import {
    GetMerchantResponse,
    GetSelectedMerchantResponse,
    GetSettingsResponse,
    IChangeDefaultLanguageRequest,
    IChangeDisputeNotificationTypeRequest,
    IChangeStatementNotificationTypeRequest,
    IUserState,
    PayloadData,
} from "redux/models/userModels";
import { SupportedLanguageEnum } from "utils/enums";
import { PageTypeEnum } from "utils/reportDefinitions";
import { getUser } from "utils/tokenActions";

const initialState: IUserState = {
    merchants: [],
    selectedMerchant: undefined,
    language: undefined,
    permissions: [],
    statementNotificationType: undefined,
    disputeNotificationType: undefined,
    isImpersonateUser: undefined,
    activeMerchant: undefined,
};

const userReducer = createSlice({
    name: "user",
    initialState,
    reducers: {
        setSelectedMerchant(state: IUserState) {
            const user = getUser();
            state.selectedMerchant = state.merchants.find(x => x.id === user.merchantId);
            state.permissions = user.permissions.map(x => x.toString());
            state.isImpersonateUser = user.impersonateUserId ? true : false;
            state.employeeRole = user.employeeRole;
        },
    },
    extraReducers: builder => {
        builder.addCase(userGetMerchants.pending, state => {});
        builder.addCase(
            userGetMerchants.fulfilled,
            (state, action: PayloadAction<PayloadData<GetMerchantResponse>>) => {
                state.merchants = action.payload.data.merchants;
            }
        );
        builder.addCase(userGetMerchants.rejected, state => {
            state.merchants = [];
        });

        builder.addCase(userGetSettings.pending, state => {});
        builder.addCase(userGetSettings.fulfilled, (state, action: PayloadAction<PayloadData<GetSettingsResponse>>) => {
            state.language = SupportedLanguageEnum[action.payload.data.language];
            state.statementNotificationType = action.payload.data.statementNotificationType;
            state.disputeNotificationType = action.payload.data.disputeNotificationType;

            // clear localstorage by pageType enum if be empty (only first time after new feature)

            if (action.payload.data.merchantUserColumnSelection.length == 0) {
                var pageTypeEnumValues = Object.values(PageTypeEnum);

                pageTypeEnumValues.forEach(element => {
                    if (localStorage.getItem(element) != null) {
                        localStorage.removeItem(element);
                    }
                });
            } else {
                action.payload.data.merchantUserColumnSelection.forEach(element => {
                    localStorage.setItem(element.pageTypeKey, element.columns);
                });
            }
        });
        builder.addCase(userGetSettings.rejected, state => {
            state.merchants = [];
        });

        builder.addCase(userSetMerchant.pending, state => {});
        builder.addCase(userSetMerchant.fulfilled, state => {});
        builder.addCase(userSetMerchant.rejected, state => {});

        builder.addCase(userChangeDefaultLanguage.pending, state => {});
        builder.addCase(
            userChangeDefaultLanguage.fulfilled,
            (state, action: PayloadAction<IChangeDefaultLanguageRequest>) => {
                state.language = SupportedLanguageEnum[action.payload.languageId];
            }
        );
        builder.addCase(userChangeDefaultLanguage.rejected, state => {});

        builder.addCase(userSelectStatementNotificationType.pending, state => {});
        builder.addCase(
            userSelectStatementNotificationType.fulfilled,
            (state, action: PayloadAction<IChangeStatementNotificationTypeRequest>) => {
                state.statementNotificationType = action.payload.statementNotificationType;
            }
        );
        builder.addCase(userSelectStatementNotificationType.rejected, state => {});

        builder.addCase(userSelectDisputeNotificationType.pending, state => {});
        builder.addCase(
            userSelectDisputeNotificationType.fulfilled,
            (state, action: PayloadAction<IChangeDisputeNotificationTypeRequest>) => {
                state.disputeNotificationType = action.payload.disputeNotificationType;
            }
        );
        builder.addCase(userSelectDisputeNotificationType.rejected, state => {});

        builder.addCase(userChangePassword.pending, state => {});
        builder.addCase(userChangePassword.fulfilled, state => {});
        builder.addCase(userChangePassword.rejected, state => {});

        builder.addCase(userGetActiveMerchant.pending, state => {});
        builder.addCase(
            userGetActiveMerchant.fulfilled,
            (state, action: PayloadAction<GetSelectedMerchantResponse>) => {
                state.activeMerchant = action.payload;
            }
        );
        builder.addCase(userGetActiveMerchant.rejected, state => {});
    },
});

const { reducer } = userReducer;
export const { setSelectedMerchant } = userReducer.actions;
export default reducer;
