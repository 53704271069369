import { t } from "i18next";
import { useSelector } from "react-redux";
import { clearActiveRow, setQueryFilters } from "redux/reducers/reportReducer";
import { RootState, useAppDispatch } from "redux/store";
import { useModalManagement } from "utils/customHooks";
import { ModalTypeEnum, PermissionType, TransactionRefundDisableReason, TransactionType } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import "./transactionsHeader.scss";
import BaseButton from "components/baseButton/baseButton";
import { IGetTransactionsResponseListData } from "../api/transactionModels";
import { DownloadIcon } from "components/icons";
import { getTransactionReceiptReport, getTransactionRefundReport } from "../api/transactionApi";
import { getDownloadPdfFileMetadata, saveFile } from "utils/helperFunctions";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import SmallLoader from "components/baseLoader/smallLoader";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { ILookupResponse } from "utils/models";

const TransactionsHeader = () => {
    const dispatch = useAppDispatch();
    const modalManagement = useModalManagement();
    const activeRowData: IGetTransactionsResponseListData = useSelector((state: RootState) => state.report.activeRow);
    const userPermissions = useSelector((state: RootState) => state.user.permissions);
    const queryFilters = useSelector((state: RootState) => state.report.queryParams.filters);
    const [isRefundButtonEnabled, setIsRefundButtonEnabled] = useState(false);
    const [isRefundButtonLoading, setIsRefundButtonLoading] = useState(false);
    const [refundDisableReason, setRefundDisableReason] = useState<ILookupResponse<TransactionRefundDisableReason>>();
    const controller = new AbortController();

    useEffect(() => {
        if (activeRowData != null) {
            const transactionId = activeRowData.transactionId;
            setIsRefundButtonEnabled(() => false);
            setIsRefundButtonLoading(() => true);

            fetchTransactionDetails(transactionId);
        }

        return () => {
            controller.abort();
        };
    }, [activeRowData]);

    const fetchTransactionDetails = async (transactionId: string) => {
        try {
            const response = await executeAxiosRequestWithRefresh({
                url: `/api/transactions/transaction-details`,
                method: "GET",
                params: { transactionId },
                signal: controller.signal,
                disableErrorToast: true,
            });

            setIsRefundButtonEnabled(response.data.isRefundEnabled);
            setRefundDisableReason(response.data.transactionRefundDisableReason);
            setIsRefundButtonLoading(() => false);
        } catch (error) {
            // @ts-ignore
            if (error!.code !== "ERR_CANCELED") {
                toast.error(
                    `${t(StringResources.pages.transactions.details.getIsTransactionRefundEnabledDetailsError)}`
                );
            }
        }
    };

    const handleRefundClick = async () => {
        const modalData = await modalManagement.openModal({ modalType: ModalTypeEnum.RefundTransaction });
        if (modalData) {
            // we are adding random query params to invalidate redux cache, and initiate new call to backend for up-to-date data
            dispatch(setQueryFilters({ ...queryFilters, random: new Date().toISOString() }));
            // we are clearing active row because if right panel is opened and table re-fetched it will not update the content of the right panel (drawer)
            dispatch(clearActiveRow());
        }
    };

    const printReportRefund = async () => {
        try {
            const response = await dispatch(
                getTransactionRefundReport({ transactionId: activeRowData.transactionId })
            ).unwrap();
            // todo: filename is written in content-disposition header, but we are returning only data from axios so i don't have access to that header here
            const metadata = getDownloadPdfFileMetadata();
            saveFile(response.data, metadata.filename, metadata.contentType);
        } catch (error) {
            toast.error(t(StringResources.pages.transactions.details.refundReportError).toString());
        }
    };

    const handlePrintClick = async () => {
        if (activeRowData.isPrintRefundEnabled) {
            await printReportRefund();
            return;
        }
        if (activeRowData.transactionType.value === TransactionType.Purchase) {
            await printTransactionReceipt();
            return;
        }
    };

    const printTransactionReceipt = async () => {
        try {
            const response = await getTransactionReceiptReport({ transactionId: activeRowData.transactionId });

            saveFile(response.data, response.filename, response.contentType);
        } catch (error) {
            toast.error(t(StringResources.pages.transactions.details.refundReportError).toString());
        }
    };

    const hasTransactionsWritePermission = userPermissions.some(
        x => x === PermissionType[PermissionType.TransactionsWrite]
    );

    if (!activeRowData) {
        return <></>;
    }

    return (
        <div className="transaction-header report-details__title">
            <div className="report-details__title--label">{`${t(
                StringResources.pages.transactions.details.title
            )}`}</div>

            <div className="transaction-header__title--body">
                {hasTransactionsWritePermission && (
                    <BaseButton
                        handleClick={handleRefundClick}
                        text={`${t(StringResources.pages.transactions.details.refund)}`}
                        styleType="line"
                        size="small"
                        disabled={!isRefundButtonEnabled}
                        leftIcon={isRefundButtonLoading ? <SmallLoader /> : undefined}
                        toolTip={
                            isRefundButtonEnabled
                                ? undefined
                                : refundDisableReason != undefined &&
                                  refundDisableReason.value === TransactionRefundDisableReason.Shopify
                                ? t(StringResources.pages.transactions.details.shopifyRefundInfoText).toString()
                                : undefined
                        }
                    />
                )}

                <BaseButton
                    handleClick={handlePrintClick}
                    text={`${t(StringResources.pages.transactions.details.print)}`}
                    leftIcon={<DownloadIcon />}
                    styleType="line"
                    size="small"
                    className="report-details__title--action"
                />
            </div>
        </div>
    );
};

export default TransactionsHeader;
