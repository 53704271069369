import "./smallLoader.scss";

const SmallLoader = () => {
    return (
        <div className="small-loader">
            <div className="small-loader-slider">
                <div className="spinner-sector__circle"></div>
                <div className="spinner-sector__line spinner-sector__line--first"></div>
                <div className="spinner-sector__circle"></div>
                <div className="spinner-sector__line spinner-sector__line--second"></div>
                <div className="spinner-sector__circle"></div>
            </div>
        </div>
    );
};

export default SmallLoader;
