import PayByLink from "pages/payByLink/payByLink";
import { SupportedLanguageEnum, TerminalRequestDeliveryType } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import { PageTypeEnum, ReportType } from "utils/reportDefinitions";

export const translationsIs = {
    [StringResources.modal.close]: "Hætta við",
    [StringResources.modal.confirm]: "Staðfesta",
    [StringResources.modal.delete]: "Henda",
    [StringResources.modal.cancel]: "Hætta við",
    [StringResources.modal.set]: "Stilla",
    [StringResources.modal.details]: "Nánar",
    [StringResources.modal.submit]: "Senda",
    [StringResources.modal.create]: "Búa til",
    [StringResources.modal.save]: "Vista",
    [StringResources.modal.feedback.title]: "Endurgjöf",
    [StringResources.modal.feedback.label]: "Skilaboð",
    [StringResources.modal.feedback.placeholder]: "Skrifa skilaboð hér",
    [StringResources.modal.feedback.success]: "Skilaboð hafa verið send",
    [StringResources.modal.feedback.error]: "Skilaboð voru ekki send",
    [StringResources.modal.feedback.message]: "Þjónustuver Straums mun hafa samband við fyrsta tækifæri.",
    [StringResources.modal.deleteModal.title]: "Staðfesta eyðingu",
    [StringResources.modal.deleteModal.message]: "Ertu viss um að þú viljir eyða?",
    [StringResources.modal.refundTransaction.title]: "Endurgreiða færslu?",
    [StringResources.modal.refundTransaction.message]:
        "Færslan verður endurgreidd til viðskiptavinarins. Þessa aðgerð er ekki hægt að draga til baka.",
    [StringResources.modal.refundTransaction.refund]: "Endurgreiða",
    [StringResources.modal.refundTransaction.fullRefund]: "Endurgreiða",
    [StringResources.modal.refundTransaction.partialRefund]: "Endurgreiða að hluta",
    [StringResources.modal.refundTransaction.formRefundAmount]: "Upphæð endurgreiðslu",
    [StringResources.modal.refundTransaction.formRefundAmountRequired]: "Nauðsynlegt er að fylla þetta svæði",
    [StringResources.modal.refundTransaction.formRefundAmountMin]: "Upphæð verður að vera hærri en {{min}}",
    [StringResources.modal.refundTransaction.formRefundAmountMax]: "Upphæð verður að vera lægri en  {{max}}",
    [StringResources.modal.refundTransaction.maxAmountForRefund]: "Hámarks upphæð endurgreiðslu: {{maxAmount}}",
    [StringResources.modal.refundTransaction.amountForRefund]: "Upphæð til endurgreiðslu: {{amount}}",
    [StringResources.modal.refundTransaction.success]: "Færslan hefur verið endurgreidd",
    [StringResources.modal.refundTransaction.downloadSuccess]: "Niðurhal endurgreiðslukvittunar tóks",
    [StringResources.modal.refundTransaction.downloadError]: "Villa við niðurhal endurgreiðslukvittunar",
    [StringResources.modal.refundTransaction.error]: "Villa kom upp við endurgreiðslu á færslu",
    [StringResources.modal.refundTransaction.confirmRefund]: "Staðfesta endurgreiðslu",
    [StringResources.modal.refundTransaction.confirmRefundMessage]:
        "Ertu viss um að þú viljir endurgreiða þessa færslu? Þú munt endurgreiða {{amount}} af {{maxAmount}} til viðskiptavinarins.",
    [StringResources.modal.refundTransaction.downloadRefundReceipt]: "Prenta endurgreiðslu kvittun",

    [StringResources.modal.reversalAuthorization.reversal]: "Afturköllun",
    [StringResources.modal.reversalAuthorization.success]: "Heimild hefur verið afturkölluð",
    [StringResources.modal.reversalAuthorization.error]: "Villa kom upp við að bakfæra heimild",
    [StringResources.modal.reversalAuthorization.confirmReversalMessage]: "Afturkalla heimild fyrir {{amount}}",

    [StringResources.modal.disputeDetails.respond]: "Svara",
    [StringResources.modal.disputeDetails.acceptDispute]: "Samþykkja frávik",
    [StringResources.modal.disputeDetails.originalTransaction]: "Upphafleg færsla",
    [StringResources.modal.disputeDetails.cardNumber]: "Kortanúmer",
    [StringResources.modal.disputeDetails.amount]: "Upphæð",
    [StringResources.modal.disputeDetails.purchaseDate]: "Dagsetning heimilar",
    [StringResources.modal.disputeDetails.authorizationNumber]: "Heimild",
    [StringResources.modal.disputeDetails.currency]: "Mynt",
    [StringResources.modal.disputeDetails.originalBatchId]: "Númer bunka",
    [StringResources.modal.disputeDetails.settlement]: "Uppgjör",
    [StringResources.modal.disputeDetails.dbaName]: "Nafn sölustaðar",
    [StringResources.modal.disputeDetails.agreement]: "Samningsnúmer",
    [StringResources.modal.disputeDetails.transactionNumber]: "Færslunúmer",
    [StringResources.modal.disputeDetails.arn]: "ARN",
    [StringResources.modal.disputeDetails.originalTransactionDate]: "Dagsetning færslu",
    [StringResources.modal.disputeDetails.transactionCode]: "Tegund fráviks",
    [StringResources.modal.disputeDetails.transactionType]: "Tegund færslu",
    [StringResources.modal.disputeDetails.response]: "Svar",
    [StringResources.modal.disputeDetails.date]: "Dagsetning",
    [StringResources.modal.disputeDetails.disputeEmailAddress]: "Netfang",
    [StringResources.modal.disputeDetails.attachement]: "Viðhengi",
    [StringResources.modal.disputeDetails.responseText]: "Svaraðu þessu ágreiningsskref",
    [StringResources.modal.disputeDetails.case]: "Frávik",
    [StringResources.modal.disputeDetails.chargeback]: "Endurkrafa",
    [StringResources.modal.disputeDetails.representment]: "Endurframsetning",
    [StringResources.modal.disputeDetails.getError]: "Villa kom upp við að sækja gögn",
    [StringResources.modal.disputeDetails.acceptError]: "Villa kom upp við að samþykkjae frávik",
    [StringResources.modal.disputeDetails.acceptSuccess]: "Frávik hefur verið samþykktur",
    [StringResources.modal.disputeDetails.noData]: "Engin gögn fundust. Reyndu aftur síðar",
    [StringResources.modal.disputeDetails.reasonCode]: "Ástæðukóði",
    [StringResources.modal.disputeDetails.reasonCodeDescription]: "Ástæðukóða gildi",
    [StringResources.modal.disputeDetails.reasonCodeCategory]: "Ástæðukóðaflokki",
    [StringResources.modal.disputeDetails.username]: "Notandi",

    [StringResources.modal.createPaymentLink.title]: "Stofna greiðslutengil",
    [StringResources.modal.createPaymentLink.updateTitle]: "Uppfæra greiðslutengil",
    [StringResources.modal.createPaymentLink.copyTitle]: "Afrita greiðslutengil",
    [StringResources.modal.createPaymentLink.updateButton]: "Breyta",
    [StringResources.modal.createPaymentLink.productsTitle]: "Vörur",
    [StringResources.modal.createPaymentLink.productsDescription]: "Lýsing",
    [StringResources.modal.createPaymentLink.productsDiscount]: "Afsláttur",
    [StringResources.modal.createPaymentLink.productsAmount]: "Upphæð",
    [StringResources.modal.createPaymentLink.productsQuantity]: "Magn",
    [StringResources.modal.createPaymentLink.productsUnitPrice]: "Einingaverð",
    [StringResources.modal.createPaymentLink.settingsTitle]: "Stillingar",
    [StringResources.modal.createPaymentLink.buyerInfoTitle]: "Upplýsingar korthafa",
    [StringResources.modal.createPaymentLink.salesTitle]: "Sölur",
    [StringResources.modal.createPaymentLink.languageOptionsLabel]: "Tungumál",
    [StringResources.modal.createPaymentLink.paymentPageOptionsLabel]: "Vefsamningur",
    [StringResources.modal.createPaymentLink.paymentLinkThemeOptionsLabel]: "Þema",
    [StringResources.modal.createPaymentLink.hasExpirationDateOptionsLabel]: "Gildir til",
    [StringResources.modal.createPaymentLink.doesNotExpire]: "Rennur ekki út",
    [StringResources.modal.createPaymentLink.hasExpirationDate]: "Líftíma",
    [StringResources.modal.createPaymentLink.hasExpirationDateRequired]: "Líftíma vantar",
    [StringResources.modal.createPaymentLink.payByLinkDescription]: "Lýsing greiđslutengils",
    [StringResources.modal.createPaymentLink.payByLinkDescriptionToolTip]:
        "Lýsing fyrir greiðsluhlekk. Birtist ekki korthafa.",
    [StringResources.modal.createPaymentLink.paymentPageThemeToolTip]: "Útlitsþema fyrir greiðsluferlið.",
    [StringResources.modal.createPaymentLink.paymentPageToolTip]:
        "Númer vefsamnings eða verslunar sem greiðslur fara í gegnum.",
    [StringResources.modal.createPaymentLink.purchaseReturnUrlToolTip]:
        "Slóð sem korthafa verður skilað á eftir greiðsluferlið.",
    [StringResources.modal.createPaymentLink.defaultLanguageToolTip]:
        "Tungumál greiðsluferlis. Korthafi getur svo breytt tungumálinu í greiðsluferlinu.",
    [StringResources.modal.createPaymentLink.purchaseReturnUrl]: "Vefslóð eftir greiðsluferli",
    [StringResources.modal.createPaymentLink.validTo]: "Gildir til",
    [StringResources.modal.createPaymentLink.hiddenLabel]: "Falið",
    [StringResources.modal.createPaymentLink.requiredLabel]: "Skilyrði",
    [StringResources.modal.createPaymentLink.optionalabel]: "Valkvætt",
    [StringResources.modal.createPaymentLink.address]: "Heimilisfang",
    [StringResources.modal.createPaymentLink.city]: "Borg",
    [StringResources.modal.createPaymentLink.comments]: "Athugasemdir",
    [StringResources.modal.createPaymentLink.emailAddress]: "Netfang",
    [StringResources.modal.createPaymentLink.fullName]: "Nafn",
    [StringResources.modal.createPaymentLink.postCode]: "Póstnúmer",
    [StringResources.modal.createPaymentLink.telephone]: "Sími",
    [StringResources.modal.createPaymentLink.ssn]: "Kennitala",
    [StringResources.modal.createPaymentLink.country]: "Land",
    [StringResources.modal.createPaymentLink.exactSalesCount]: "Sölufjöldi",
    [StringResources.modal.createPaymentLink.singleSales]: "Stök sala",
    [StringResources.modal.createPaymentLink.exactSales]: "Takmarkaðar sölur",
    [StringResources.modal.createPaymentLink.unlimitedSales]: "Ótakmarkaðar sölur",
    [StringResources.modal.createPaymentLink.totalAmount]: "Heildarupphæð",
    [StringResources.modal.createPaymentLink.createLinkButton]: "Búa til tengil",
    [StringResources.modal.createPaymentLink.dateToEmptyInfoMessage]:
        "Þessi tengill mun ekki renna út - Ýttu á 'Búa til tengil' til staðfestingar.",
    [StringResources.modal.createPaymentLink.amountGreaterThanAmountWithoutDiscount]:
        "Upphæð getur ekki verið hærri heldur en Upphæð án afsláttar.",
    [StringResources.modal.createPaymentLink.descriptionRequired]: "Vörulýsingu vantar",
    [StringResources.modal.createPaymentLink.quantityIsRequired]: "Magn vantar",
    [StringResources.modal.createPaymentLink.quantityMustBeAtLeastOne]: "Lágmarks magn er 1",
    [StringResources.modal.createPaymentLink.paymentPageRequired]: "Greiðslusíðu vantar",
    [StringResources.modal.createPaymentLink.paymentLinkThemeRequired]: "Greiðslutenglaþema vantar",
    [StringResources.modal.createPaymentLink.languageRequired]: "Tungumál vantar",
    [StringResources.modal.createPaymentLink.exactSalesCountRequired]: "Nákvæman sölufjölda vantar",
    [StringResources.modal.createPaymentLink.productAmountWithoutDiscount]: "Upphæð án afsláttar",
    [StringResources.modal.createPaymentLink.totalAmountMustBeHigherThanZero]: "Heildarupphæð þarf að vera hærra en 0",
    [StringResources.modal.createPaymentLink.unitPriceMustBeHigherThanZero]: "Einingaverð þarf að vera hærra en 0",
    [StringResources.modal.createPaymentLink.validToRequired]: "Gildistíma vantar",
    [StringResources.modal.createPaymentLink.salesTypeRequired]: "Sölutýpu vantar",
    [StringResources.modal.createPaymentLink.successMessage]: "Greiðslutengill var stofnaður",
    [StringResources.modal.createPaymentLink.successUpdateMessage]: "Greiðslutengill var uppfærður",
    [StringResources.modal.createPaymentLink.errorUpdateMessage]: "Ekki tókst að uppfæra greiðslutengil",
    [StringResources.modal.createPaymentLink.errorMessage]: "Ekki tókst að stofna Greiðslutengil",
    [StringResources.modal.createPaymentLink.fetchPaymentPageError]:
        "Ekki tókst að sækja valmöguleika fyrir greiðslusíðu",
    [StringResources.modal.createPaymentLink.fetchPaymentLinkDetailsError]:
        "Ekki tókst að ná í upplýsingar fyrir greiðslutengil",
    [StringResources.modal.createPaymentLink.incorrectUrl]: "Vefslóð er ekki gild",

    [StringResources.modal.payByLink.disableLinkActionButton]: "Loka",
    [StringResources.modal.payByLink.disableLinkConfirmMessage]:
        "Loka greiðslutengli {{description}} - að upphæð {{amount}} {{currency}}?",
    [StringResources.modal.payByLink.disableLinkTitle]: "Loka?",

    [StringResources.modal.apiKeys.disableKeyTitle]: "Afvirkja lykil",
    [StringResources.modal.apiKeys.disableKeyMessage]:
        "Þú munt aftengja API lykil ({{keyName}}) fyrir samning(a). Aðgerðin mun taka gildi strax og ekki er hægt að snúa við. Halda áfram?",
    [StringResources.modal.apiKeys.generateKeyTitle]: "Stofna nýjan API lykil",
    [StringResources.modal.apiKeys.generateKeyMessage]:
        "Afrita lykil er opið í 24 tíma eftir að lykill er stofnaður. Eftir þann tíma er ekki hægt afrita lykil og nýr lykill þarf að vera stofnaður í staðinn.",
    [StringResources.modal.apiKeys.subscribeToAllAgreements]: "Virkja á alla virka og nýja samninga",
    [StringResources.modal.apiKeys.generateKey]: "Stofna lykil",
    [StringResources.modal.apiKeys.editKeyTitle]: "Breyta lykil",
    [StringResources.modal.apiKeys.keyNameIsRequired]: "Nafn á lykil vantar",

    [StringResources.modal.webhooks.disableWebhookTitle]: "Afvirkja webhook",
    [StringResources.modal.webhooks.disableWebhookMessage]:
        "Þú ert í þann mund að fara að afvirkja {{webhookName}} {{webhookUrl}} Þessi aðgerð er óafturkræf. Viltu halda áfram?",
    [StringResources.modal.webhooks.generateWebhookTitle]: "Skrá nýjan webhook",
    [StringResources.modal.webhooks.generateWebhookMessage]:
        "'Afrita' virknin verður í boði í 24 klst eftir að Webhook lykill hefur verið stofnaður. Eftir þann tíma verður 'Afrita' óvirkt og stofna þarf þá nýjan Webhook lykil ef þessi týnist.",
    [StringResources.modal.webhooks.subscribeToAllAgreements]: "Tengjast öllum samningum",
    [StringResources.modal.webhooks.generateWebhook]: "Skrá nýjan webhook",
    [StringResources.modal.webhooks.editWebhookTitle]: "Breyta webhook",

    [StringResources.errorBoundary.title]: "Afsakaðu, eitthvað hefur farið úrskeðis",
    [StringResources.errorBoundary.subtitle]: "(Síðan verður endurræst eftir {{restartInterval}})",
    [StringResources.errorBoundary.btnText]: "Endurræsa núna",

    [StringResources.mainNavigation.dashboard]: "Mælaborð",
    [StringResources.mainNavigation.payments]: "Útborganir",
    [StringResources.mainNavigation.terminals]: "Útstöðvar",
    [StringResources.mainNavigation.agreements]: "Samningar",
    [StringResources.mainNavigation.dailyBalance]: "Dagleg staða",
    [StringResources.mainNavigation.settlements]: "Uppgjör",
    [StringResources.mainNavigation.batches]: "Bunkar",
    [StringResources.mainNavigation.transactions]: "Færslur",
    [StringResources.mainNavigation.authorizations]: "Heimildir",
    [StringResources.mainNavigation.exceptions]: "Frávik",
    [StringResources.mainNavigation.ecomThemes]: "Þemu",
    [StringResources.mainNavigation.users]: "Notendur",
    [StringResources.mainNavigation.electronicDocuments]: "Skjöl",
    [StringResources.mainNavigation.notifications]: "Tilkynningar",
    [StringResources.mainNavigation.settings]: "Stillingar",
    [StringResources.mainNavigation.termsOfService]: "Notkunarskilmálar",
    [StringResources.mainNavigation.logout]: "Skrá út",
    [StringResources.mainNavigation.overview]: "Yfirlit",
    [StringResources.mainNavigation.payByLink]: "Greiðslutenglar",
    [StringResources.mainNavigation.apiKeys]: "API lyklar",
    [StringResources.mainNavigation.access]: "Aðgangur",
    [StringResources.mainNavigation.ecom]: "Veflausnir",
    [StringResources.mainNavigation.webhooks]: "Webhooks",

    [StringResources.userMenu.back]: "Til baka",
    [StringResources.filter.buttonText]: "Sía",
    [StringResources.filter.totalSubtotal]: "Samtals",
    [StringResources.filter.clear]: "Hreinsa síu",
    [StringResources.filter.search]: "Leita",
    [StringResources.filter.dropdownSearchPlaceholder]: "Ýttu til að skrifa",
    [StringResources.filter.currency]: "Mynt",
    [StringResources.filter.today]: "Í dag",
    [StringResources.filter.yesterday]: "Í gær",
    [StringResources.filter.lastWeek]: "Í síðustu viku",
    [StringResources.filter.lastMonth]: "Í síðasta mánuði",
    [StringResources.filter.thisWeek]: "Í þessari viku",
    [StringResources.filter.thisMonth]: "Í þessum mánuði",
    [StringResources.filter.thisYear]: "Í ár",
    [StringResources.columnChooser.column]: "Dálkar",
    [StringResources.columnChooser.unsavedChanges]: "Þú ert með óvistaðar breytingar!",
    [StringResources.columnChooser.save]: "Vista og loka",
    [StringResources.columnChooser.cancel]: "Hætta við",
    [StringResources.columnChooser.selectAll]: "Velja allt",
    [StringResources.columnChooser.defaultView]: "Sjálfgefið útsýni",

    [StringResources.reportDetails.boolean.yesLabel]: "Já",
    [StringResources.reportDetails.boolean.noLabel]: "Nei",
    [StringResources.reportDetails.nonFinancial]: "Ófjárhagsleg færsla",
    [StringResources.table.cell.boolean.yesLabel]: "Já",
    [StringResources.table.cell.boolean.noLabel]: "Nei",
    [StringResources.table.cell.copyToClipboardSuccess]: "Afritað á klippiborð",
    [StringResources.table.fallbackMessage.default]: "Ekkert {{pageName}} fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.agreements]: "Enginn samningur fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.batches]: "Enginn bunki fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.payments]: "Engin útbogun fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.settlements]: "Ekkert uppgjör fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.transactions]: "Enginn færsla fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.authorizations]: "Engin heimild fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.disputes]: "Engin frávik fundust{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.employees]: "Enginn notandi fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.electronicDocuments]: "Ekkert skjöl fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.dailyBalance]: "Engin staða fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.terminals]: "Engin útstöð fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.none]: "Engin gögn fundust{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.dashboard]: "Engin gögn fundust{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.notFound]: "Engin gögn fundust{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessageWithFilter]: " - prufaðu að breyta síu stillingunum",
    [StringResources.table.itemsPerPage]: "Atriði á síðu",
    [StringResources.report.export]: "Sækja gögn",
    [StringResources.report.print]: "Prenta",
    [StringResources.report.download]: "Sækja skjöl",
    [StringResources.toolTip.batches.searchBy]: "Leita eftir:",
    [StringResources.toolTip.batches.batchId]: "Bunkanúmer",
    [StringResources.toolTip.batches.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.toolTip.batches.processorId]: "Númer vinnsluaðila",
    [StringResources.toolTip.batches.terminalId]: "Númer útstöðvar",
    [StringResources.toolTip.payments.searchBy]: "Leita eftir:",
    [StringResources.toolTip.payments.agreementNumber]: "Samningsnúmer",
    [StringResources.toolTip.payments.dbaNames]: "Nafn sölustaðar",
    [StringResources.toolTip.payments.settlements]: "Uppgjör",
    [StringResources.toolTip.settlements.searchBy]: "Leita eftir:",
    [StringResources.toolTip.settlements.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.toolTip.settlements.agreementId]: "Samningsnúmer",
    [StringResources.toolTip.settlements.dbaNames]: "Nafn sölustaðar",
    [StringResources.toolTip.transactions.searchBy]: "Leita eftir:",
    [StringResources.toolTip.transactions.transactionId]: "Færslunúmer",
    [StringResources.toolTip.transactions.authNumber]: "Heimildarnúmer",
    [StringResources.toolTip.transactions.arn]: "Arn (tilvísunarnúmer færsluhirðis)",
    [StringResources.toolTip.transactions.terminalId]: "Númer útstöðvar",
    [StringResources.toolTip.transactions.physicalTerminalId]: "Búnaðarnúmer útstöðvar",
    [StringResources.toolTip.transactions.batchNumber]: "Bunkanúmer",
    [StringResources.toolTip.transactions.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.toolTip.transactions.agreementId]: "Samningsnúmer",
    [StringResources.toolTip.transactions.partnerId]: "Númer samstarfsaðila",
    [StringResources.toolTip.transactions.externalTransactionId]: "Utanaðkomandi færslunúmer",
    [StringResources.pages.transactions.details.title]: "Færslur",
    [StringResources.pages.transactions.details.refund]: "Endurgreiða",
    [StringResources.pages.transactions.details.print]: "Prenta",
    [StringResources.pages.transactions.details.refundReportError]: "Villa kom upp við að sækja endurgreiðslu kvittun",
    [StringResources.pages.transactions.details.transactionReceiptError]: "Villa kom upp við niðurhal á færslukvittun",
    [StringResources.pages.transactions.details.getIsTransactionRefundEnabledDetailsError]:
        "Error while fetching is transaction refundable details",
    [StringResources.pages.transactions.details.shopifyRefundInfoText]:
        "Shopify endurgreiðslur eru gerðar í gegnum stjórnborð Shopify",
    [StringResources.pages.agreements.title]: "Samningar",
    [StringResources.pages.agreements.viewSettlements]: "Skoða uppgjör",
    [StringResources.pages.agreements.agreementNumber]: "Samningsnúmer",
    [StringResources.pages.agreements.createdTime]: "Stofndagur",
    [StringResources.pages.agreements.type]: "Tegund samnings",
    [StringResources.pages.agreements.status]: "Staða",
    [StringResources.pages.agreements.currency]: "Mynt",
    [StringResources.pages.agreements.currencyCode]: "Myntkóði",
    [StringResources.pages.agreements.netBalance]: "Staða samnings",
    [StringResources.pages.agreements.grossBalance]: "Heildarstaða",
    [StringResources.pages.agreements.grossBalanceToolTip]: "Heildarstaða án gjalda",
    [StringResources.pages.agreements.merchantNumber]: "Söluaðilanúmer",
    [StringResources.pages.agreements.merchantName]: "Nafn söluaðila",
    [StringResources.pages.agreements.merchantRegistrationNumber]: "Kennitala",
    [StringResources.pages.agreements.isCardPresent]: "Tegund samnings",
    [StringResources.pages.authorizations.title]: "Heimildir",
    [StringResources.pages.authorizations.search]: "Heimildarnúmer",
    [StringResources.pages.authorizations.dateFrom]: "Dagsetning frá",
    [StringResources.pages.authorizations.dateTo]: "Dagsetning til",
    [StringResources.pages.authorizations.amountFrom]: "Upphæð frá",
    [StringResources.pages.authorizations.amountTo]: "Upphæð til",
    [StringResources.pages.authorizations.cardNumber]: "Kortanúmer",
    [StringResources.pages.authorizations.agreementId]: "Samningsnúmer",
    [StringResources.pages.authorizations.agreementNumber]: "Samningsnúmer",
    [StringResources.pages.authorizations.terminalId]: "Númer útstöðvar",
    [StringResources.pages.authorizations.authorizationCode]: "Heimildarnúmer",
    [StringResources.pages.authorizations.lifeCycleId]: "Líftíma númer",
    [StringResources.pages.authorizations.amount]: "Upphæð",
    [StringResources.pages.authorizations.state]: "Staða",
    [StringResources.pages.authorizations.par]: "PAR",
    [StringResources.pages.authorizations.authorizationDateTime]: "Dagsetning heimildar",
    [StringResources.pages.authorizations.authorizationEventType]: "Tegund",
    [StringResources.pages.authorizations.transactionId]: "Færslunúmer",
    [StringResources.pages.authorizations.authorizationStatus]: "Staða",
    [StringResources.pages.authorizations.responseCode]: "Svarkóði",
    [StringResources.pages.authorizations.dbaName]: "Nafn sölustaðar",
    [StringResources.pages.authorizations.deviceId]: "Númer tækis",
    [StringResources.pages.authorizations.isRefund]: "Er Endurgreiða",
    [StringResources.pages.authorizations.scheme]: "Kortafélag",
    [StringResources.pages.authorizations.merchantName]: "Nafn söluaðila",
    [StringResources.pages.authorizations.details.title]: "Heimildir",
    [StringResources.pages.authorizations.details.reverse]: "Afturkalla",
    [StringResources.pages.authorizations.details.getAuthorizationsDetailsError]:
        "Error while fetching authorization details",
    [StringResources.pages.authorizations.additionalData]: "Auka gögn",
    [StringResources.pages.authorizations.authorizationId]: "Númer heimildar",
    [StringResources.pages.authorizations.viewTransactions]: "Skoða færslu",
    [StringResources.pages.authorizations.externalTransactionId]: "Utanaðkomandi færslunúmer",
    [StringResources.pages.authorizations.paymentAccountReference]: "PAR",
    [StringResources.pages.batches.title]: "Bunkar",
    [StringResources.pages.batches.viewTransactions]: "Skoða færslur",
    [StringResources.pages.batches.feesAndAdditions]: "Gjöld og viðbætur ",
    [StringResources.pages.batches.serviceCharges]: "Þjónustugjöld",
    [StringResources.pages.batches.refund]: "Endurgreiða",
    [StringResources.pages.batches.search]: "Leita í bunkum",
    [StringResources.pages.batches.allAgreements]: "Allir samningar",
    [StringResources.pages.batches.status]: "Staða",
    [StringResources.pages.batches.dateFrom]: "Dagsetning frá",
    [StringResources.pages.batches.dateTo]: "Dagsetning til",
    [StringResources.pages.batches.amountFrom]: "Upphæð frá",
    [StringResources.pages.batches.amountTo]: "Upphæð til",
    [StringResources.pages.batches.settledFrom]: "Uppgjör frá",
    [StringResources.pages.batches.settledTo]: "Uppgjör til",
    [StringResources.pages.batches.paidFrom]: "Greitt frá",
    [StringResources.pages.batches.paidTo]: "Greitt til",
    [StringResources.pages.batches.settlementType]: "Tegund uppgjörs",
    [StringResources.pages.batches.agreementId]: "Samningsnúmer",
    [StringResources.pages.batches.dbaName]: "Nafn sölustaðar",
    [StringResources.pages.batches.date]: "Stofndagur",
    [StringResources.pages.batches.batch]: "Bunki",
    [StringResources.pages.batches.batchTotal]: "Upphæð bunka",
    [StringResources.pages.batches.transactions]: "Færslur",
    [StringResources.pages.batches.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.pages.batches.processorId]: "Númer vinnsluaðila",
    [StringResources.pages.batches.chargeback]: "Endurkrafa",
    [StringResources.pages.batches.currency]: "Mynt",
    [StringResources.pages.batches.gross]: "Brúttó",
    [StringResources.pages.batches.netAmount]: "Nettó upphæð",
    [StringResources.pages.batches.paid]: "Greitt",
    [StringResources.pages.batches.partnerId]: "Númer samstarfsaðila",
    [StringResources.pages.batches.settled]: "Greitt",
    [StringResources.pages.batches.terminalId]: "Númer útstöðvar",

    [StringResources.pages.batches.details.grossPurchase]: "Heildarsala",
    [StringResources.pages.batches.details.refundAmount]: "Endurgreiðslur",
    [StringResources.pages.batches.details.reversals]: "Afturkallanir",
    [StringResources.pages.batches.details.grossBatch]: "Upphæð (brúttó)",
    [StringResources.pages.batches.details.feeAmount]: "Upphæð gjalda",
    [StringResources.pages.batches.details.transactionFee]: "Færslugjald",
    [StringResources.pages.batches.details.joiningFee]: "Stofngjald",
    [StringResources.pages.batches.details.chargebackFees]: "Endurkröfugjald",
    [StringResources.pages.batches.details.swiftFees]: "Swift gjöld",
    [StringResources.pages.batches.details.rejected]: "Hafnað",
    [StringResources.pages.batches.details.representment]: "Endursend færsla",
    [StringResources.pages.batches.details.posRentalFee]: "Posaleiga m. vsk",
    [StringResources.pages.batches.details.preArbitration]: "Pre-arbitration",
    [StringResources.pages.batches.details.netBatch]: "Upphæð (nettó)",
    [StringResources.pages.batches.details.breakdown]: "Sundurliðun",
    [StringResources.pages.batches.details.brandType]: "Kortafélag",
    [StringResources.pages.batches.details.transactions]: "Færslur",
    [StringResources.pages.batches.details.viewSettlement]: "Skoða uppgjör",

    [StringResources.pages.dailyBalance.title]: "Dagleg staða",
    [StringResources.pages.dailyBalance.agreementNumber]: "Samningsnúmer",
    [StringResources.pages.dailyBalance.dateFrom]: "Dagsetning frá",
    [StringResources.pages.dailyBalance.dateTo]: "Dagsetning til",
    [StringResources.pages.dailyBalance.merchantNumber]: "Söluaðilanúmer",
    [StringResources.pages.dailyBalance.partnerId]: "Númer samstarfsaðila",
    [StringResources.pages.dailyBalance.currency]: "Mynt",
    [StringResources.pages.dailyBalance.date]: "Dagsetning",
    [StringResources.pages.dailyBalance.netBalance]: "Nettó staða",
    [StringResources.pages.dailyBalance.netBalanceTooltip]: "Dagleg uppsöfnuð staða samnings",
    [StringResources.pages.dailyBalance.grossBalance]: "Brúttó staða",
    [StringResources.pages.dailyBalance.grossBalanceTooltip]: "Dagleg uppsöfnuð brúttóstaða samnings",
    [StringResources.pages.dailyBalance.paidAmount]: "Greiðslur dagsins",
    [StringResources.pages.dailyBalance.paidAmountTooltip]: "Greiðsla dagsins greidd til söluaðila",
    [StringResources.pages.dashboard.title]: "Mælaborð",
    [StringResources.pages.dashboard.date]: "Dagsetning",
    [StringResources.pages.dashboard.setSalesTarget]: "Skrá sölumarkmið",
    [StringResources.pages.dashboard.targetAmount]: "Sölumarkmið",
    [StringResources.pages.dashboard.setSalesTarget]: "Skrá sölumarkmið",
    [StringResources.pages.electronicDocuments.title]: "Skjöl",
    [StringResources.pages.electronicDocuments.date]: "Dagsetning",
    [StringResources.pages.electronicDocuments.documentName]: "Nafn skjals",
    [StringResources.pages.electronicDocuments.dateCreated]: "Stofndagur skráar",
    [StringResources.pages.electronicDocuments.documentType]: "Skjalategund",
    [StringResources.pages.electronicDocuments.agreementNumber]: "Samningsnúmer",
    [StringResources.pages.electronicDocuments.ssn]: "Kennitala",
    [StringResources.pages.electronicDocuments.authorizationDateTime]: "Dagsetning heimildar",
    [StringResources.pages.electronicDocuments.amount]: "Upphæð",
    [StringResources.pages.electronicDocuments.dbaName]: "Nafn sölustaðar",
    [StringResources.pages.electronicDocuments.cardNumber]: "Kortanúmer",
    [StringResources.pages.electronicDocuments.details.download]: "Download",
    [StringResources.pages.electronicDocuments.details.fileDownloadError]: "Download error",
    [StringResources.pages.electronicDocuments.details.fileDownloadSuccess]: "Downloaded",
    [StringResources.pages.electronicDocuments.printStarted]: "Í vinnslu",
    [StringResources.pages.payments.title]: "Útborganir",
    [StringResources.pages.payments.breakdown]: "Sundurliðun uppgjörs",

    [StringResources.pages.payments.breakdownNoRecords]: "Engar færslur fundust.",

    [StringResources.pages.payments.settlement]: "Uppgjör",
    [StringResources.pages.payments.netPurchase]: "Færslur alls",
    [StringResources.pages.payments.payout]: "útborgun",
    [StringResources.pages.payments.search]: "Leita eftir Útborganir",
    [StringResources.pages.payments.dateFrom]: "Dagsetning frá",
    [StringResources.pages.payments.dateTo]: "Dagsetning til",
    [StringResources.pages.payments.amountFrom]: "Upphæð frá",
    [StringResources.pages.payments.amountTo]: "Upphæð til",
    [StringResources.pages.payments.agreementNumber]: "Samningsnúmer",
    [StringResources.pages.payments.currency]: "Mynt",
    [StringResources.pages.payments.swift]: "Swift",
    [StringResources.pages.payments.merchantName]: "Nafn söluaðila",
    [StringResources.pages.payments.iban]: "IBAN",
    [StringResources.pages.payments.payoutAmount]: "Útborgun",
    [StringResources.pages.payments.feeAmounts]: "Upphæð gjalda",
    [StringResources.pages.payments.grossAmounts]: "Brúttó upphæð",
    [StringResources.pages.payments.paidDate]: "Dagsetning greiðslu",
    [StringResources.pages.payments.scheduled]: "Áætlað til greiðslu næst",
    [StringResources.pages.payments.partnerId]: "Númer samstarfsaðila",
    [StringResources.pages.payments.partnerName]: "Nafn samstarfsaðila",
    [StringResources.pages.payments.dbaNames]: "Nöfn sölustaða",
    [StringResources.pages.payments.ssn]: "Kennitala",
    [StringResources.pages.payments.settlementType]: "Tegund uppgjörs",
    [StringResources.pages.payments.settlements]: "Uppgjör",
    [StringResources.pages.payments.printReportError]: "Ekki tókst að sækja skjal",
    [StringResources.pages.payments.printStarted]: "Í vinnslu",
    [StringResources.pages.settlements.title]: "Uppgjör",
    [StringResources.pages.settlements.breakdown]: "Sundurliðun",
    [StringResources.pages.settlements.search]: "Leita að uppgjöri",
    [StringResources.pages.settlements.paidDateFrom]: "Greitt frá",
    [StringResources.pages.settlements.paidDateTo]: "Greitt til",
    [StringResources.pages.settlements.createdDateFrom]: "Stofndagur frá",
    [StringResources.pages.settlements.createdDateTo]: "Stofndagur til",
    [StringResources.pages.settlements.amountFrom]: "Upphæð frá",
    [StringResources.pages.settlements.amountTo]: "Upphæð til",
    [StringResources.pages.settlements.allAgreements]: "Allir samningar",
    [StringResources.pages.settlements.currency]: "Mynt",
    [StringResources.pages.settlements.grossPurchase]: "Heildarsala",
    [StringResources.pages.settlements.grossSettlement]: "Uppgjör (brúttó)",
    [StringResources.pages.settlements.feeAmount]: "Upphæð gjalda",
    [StringResources.pages.settlements.totalFees]: "Samtals gjöld",
    [StringResources.pages.settlements.netSettlement]: "Uppgjör (nettó)",
    [StringResources.pages.settlements.batches]: "Bunkar",
    [StringResources.pages.settlements.viewBatches]: "Skoða bunka",
    [StringResources.pages.settlements.viewTransactions]: "Skoða færslur",
    [StringResources.pages.settlements.dbaName]: "Nafn sölustaðar",
    [StringResources.pages.settlements.gross]: "Brúttó",
    [StringResources.pages.settlements.netPurchase]: "Færslur alls",
    [StringResources.pages.settlements.date]: "Dagsetning",
    [StringResources.pages.settlements.batch]: "Bunki",

    [StringResources.pages.settlements.batchNoRecords]: "Engar skrár fundust.",

    [StringResources.pages.settlements.batchTotal]: "Upphæð",
    [StringResources.pages.settlements.batchNumber]: "Bunkanúmer",
    [StringResources.pages.settlements.status]: "Staða",
    [StringResources.pages.settlements.settlementFee]: "Uppgjörsgjald",
    [StringResources.pages.settlements.paid]: "Greitt",
    [StringResources.pages.settlements.agreementId]: "Samningsnúmer",
    [StringResources.pages.settlements.settlementType]: "Tegund uppgjörs",
    [StringResources.pages.settlements.refundAmount]: "Endurgreiðslur",
    [StringResources.pages.settlements.createdDate]: "Stofndagur",
    [StringResources.pages.settlements.scheduledDate]: "Áætlað til greiðslu næst",
    [StringResources.pages.settlements.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.pages.settlements.batchesCount]: "Bunkar",
    [StringResources.pages.settlements.transactionsCount]: "Færslur",
    [StringResources.pages.settlements.cumulativeFees]: "Uppsöfnuð gjöld",
    [StringResources.pages.settlements.payout]: "Útborgað",
    [StringResources.pages.settlements.partnerId]: "Númer samstarfsaðila",
    [StringResources.pages.settlements.partnerName]: "Nafn samstarfsaðila",
    [StringResources.pages.settlements.deduction]: "Frádráttur",
    [StringResources.pages.settlements.representment]: "Endursend færsla",
    [StringResources.pages.settlements.chargeback]: "Endurkrafa",
    [StringResources.pages.settlements.rollingReserve]: "Veltutengd biðgreiðsla",
    [StringResources.pages.settlements.rollingRelease]: "Biðgreiðsla til útborgunar",
    [StringResources.pages.settlements.chargebackFees]: "Endurkröfugjald",
    [StringResources.pages.settlements.swiftFees]: "Swift gjöld",
    [StringResources.pages.settlements.transactionFee]: "Færslugjald",
    [StringResources.pages.settlements.joiningFee]: "Stofngjald",
    [StringResources.pages.settlements.rejected]: "Hafnað",
    [StringResources.pages.settlements.addedSum]: "Bætt við uppgjör",
    [StringResources.pages.settlements.deductedSum]: "Dregið frá uppgjöri",
    [StringResources.pages.settlements.reversals]: "Afturkallanir",
    [StringResources.pages.settlements.preArbitration]: "Pre-arbitration",
    [StringResources.pages.settlements.posRentalFee]: "Posaleiga m. vsk",
    [StringResources.pages.settlements.exceptionCorrection]: "Lagfæring færslu",
    [StringResources.pages.settlements.dccCommission]: "DCC þóknun",
    [StringResources.pages.settlements.dbaNames]: "Nafn sölustaðar",
    [StringResources.pages.settlements.ssn]: "Kennitala",
    [StringResources.pages.settlements.claim]: "Krafa",
    [StringResources.pages.settlements.claimDueDate]: "Gjalddagi kröfu",
    [StringResources.pages.settlements.aukakronurFee]: "Aukakrónur",
    [StringResources.pages.settlements.otherFees]: "Önnur gjöld",
    [StringResources.pages.settlements.gomobileFee]: "Gomobile",
    [StringResources.pages.settlements.gomobileInitialFee]: "Gomobile stofngjald",
    [StringResources.pages.settlements.preAuthorizationFee]: "Frátekin heimild",
    [StringResources.pages.settlements.monthlyFee]: "Mánaðargjald",
    [StringResources.pages.settlements.authorizationFee]: "Heimildagjald",
    [StringResources.pages.settlements.minimumMonthlyServiceFee]: "Lágmark þjónustugjald",
    [StringResources.pages.settlements.cardNotPresentFee]: "Gjald vegna netfærslna",
    [StringResources.pages.settlements.pciFee]: "PCI mánaðargjald",
    [StringResources.pages.settlements.mobileAirTimeFee]: "Þráðlaus posi, símanotkun",
    [StringResources.pages.settlements.ecomGatewayMonthlyFee]: "Mánaðargjald greiðslugáttar",
    [StringResources.pages.settlements.ecomGatewayTransactionFee]: "Færslugjald netfærslna",
    [StringResources.pages.settlements.merchantName]: "Söluaðili",
    [StringResources.pages.settlements.print]: "Prenta",
    [StringResources.pages.settlements.printReportError]: "Ekki tókst að sækja skjal",
    [StringResources.pages.settlements.viewPayment]: "Skoða greiðslu",
    [StringResources.pages.settlements.hasInvoice]: "Bankakrafa",
    [StringResources.pages.transactions.title]: "Færslur",
    [StringResources.pages.transactions.search]: "Leita í færslum",
    [StringResources.pages.transactions.period]: "Tímabil",
    [StringResources.pages.transactions.purchaseDateFrom]: "Kaupdagur frá",
    [StringResources.pages.transactions.purchaseDateTo]: "Kaupdagur til",
    [StringResources.pages.transactions.amountFrom]: "Upphæð frá",
    [StringResources.pages.transactions.amountTo]: "Upphæð til",
    [StringResources.pages.transactions.cardNumber]: "Kortanúmer",
    [StringResources.pages.transactions.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.pages.transactions.cardType]: "Kortategund",
    [StringResources.pages.transactions.cardIssuer]: "Kortafélag",
    [StringResources.pages.transactions.registrationDateFrom]: "Skráningardagur frá",
    [StringResources.pages.transactions.registrationDateTo]: "Skráningardagur til",
    [StringResources.pages.transactions.paidDateFrom]: "Greitt frá",
    [StringResources.pages.transactions.paidDateTo]: "Greitt til",
    [StringResources.pages.transactions.settlementType]: "Tegund uppgjörs",
    [StringResources.pages.transactions.transactionId]: "Færslunúmer",
    [StringResources.pages.transactions.authNumber]: "Heimildarnúmer",
    [StringResources.pages.transactions.transactionCode]: "Færslukóði",
    [StringResources.pages.transactions.authorizationDate]: "Dagsetning heimildar",
    [StringResources.pages.transactions.merchant]: "Söluaðili",
    [StringResources.pages.transactions.dbaName]: "Nafn sölustaðar",
    [StringResources.pages.transactions.referenceData]: "Tilvísun",
    [StringResources.pages.transactions.externalTransactionId]: "Utanaðkomandi færslunúmer",
    [StringResources.pages.transactions.purchaseDate]: "Kaupdagur",
    [StringResources.pages.transactions.originalAmount]: "Upprunaleg upphæð",
    [StringResources.pages.transactions.gross]: "Brúttó upphæð",
    [StringResources.pages.transactions.netAmount]: "Nettó upphæð",
    [StringResources.pages.transactions.schemeFee]: "Kortafélagsgjald",
    [StringResources.pages.transactions.schemeFeeCurrency]: "Mynttegund kortafélagsgjalds",
    [StringResources.pages.transactions.currency]: "Mynt",
    [StringResources.pages.transactions.secure3D]: "3D Secure",
    [StringResources.pages.transactions.batch]: "Bunki",
    [StringResources.pages.transactions.registrationNumber]: "Kennitala",
    [StringResources.pages.transactions.reasonCode]: "Ástæðukóði",
    [StringResources.pages.transactions.cardholderAmount]: "Upphæð korthafa",
    [StringResources.pages.transactions.cardholderCurency]: "Mynt korthafa",
    [StringResources.pages.transactions.cashbackAmount]: "Upphæð gefin til baka",
    [StringResources.pages.transactions.agreementId]: "Samningsnúmer",
    [StringResources.pages.transactions.merchantBucket]: "Gjaldaflokkur",
    [StringResources.pages.transactions.physicalTermianlId]: "Búnaðarnúmer útstöðvar",
    [StringResources.pages.transactions.interchange]: "Milligjöld",
    [StringResources.pages.transactions.lifeCycleId]: "LifeCycle númer",
    [StringResources.pages.transactions.paid]: "Greitt",
    [StringResources.pages.transactions.terminalId]: "Númer útstöðvar",
    [StringResources.pages.transactions.arn]: "Arn (tilvísunarnúmer færsluhirðis)",
    [StringResources.pages.transactions.fees]: "Gjöld",
    [StringResources.pages.transactions.originalCurrency]: "Upprunaleg mynt",
    [StringResources.pages.transactions.partnerId]: "Númer samstarfsaðila",
    [StringResources.pages.transactions.registrationDate]: "Skráningardagur",
    [StringResources.pages.transactions.transactionType]: "Tegund færslu",
    [StringResources.pages.transactions.merchantFee]: "Þjónustugjald",
    [StringResources.pages.transactions.schemeFeePercent]: "Hlutfall kortafélagagjalds",
    [StringResources.pages.transactions.schemeFeeBase]: "Grunngjald kortafélaga",
    [StringResources.pages.transactions.schemeFeeFixed]: "Fast kortafélagagjald",
    [StringResources.pages.transactions.authorizationCode]: "Heimildarnúmer",
    [StringResources.pages.transactions.cardEntryModeType]: "Kortainnsláttartegund",
    [StringResources.pages.transactions.authenticationMethodType]: "Auðkenningaraðferð",
    [StringResources.pages.transactions.authorizationMethodType]: "Heimildaraðferð",
    [StringResources.pages.transactions.additionalData]: "Auka gögn",
    [StringResources.pages.transactions.isReversal]: "Er afturköllun",
    [StringResources.pages.transactions.refundStatus]: "Staða endurgreiðslu",
    [StringResources.pages.transactions.refundAmount]: "Upphæð endurgreiðslu",
    [StringResources.pages.transactions.transactionRefundBreakdown.title]: "Endurgreiðslubeiðnir",
    [StringResources.pages.transactions.transactionRefundBreakdown.viewRefunds]: "Skoða endurgreiðslur",
    [StringResources.pages.transactions.transactionRefundBreakdown.noRecords]: "Engar endurgreiðslur",
    [StringResources.pages.transactions.transactionRefundBreakdown.originalTransaction]: "Upprunafærsla",
    [StringResources.pages.transactions.transactionRefundBreakdown.refundedOf]:
        "Endurgreitt {{amount}} af {{totalAmount}}",
    [StringResources.pages.transactions.transactionRefundBreakdown.user]: "Notandi",
    [StringResources.pages.transactions.transactionRefundBreakdown.date]: "Dags.",
    [StringResources.pages.transactions.transactionRefundBreakdown.amount]: "Upphæð",
    [StringResources.pages.transactions.viewAuthorization]: "Skoða heimild",
    [StringResources.pages.terminals.title]: "Útstöðvar",
    [StringResources.pages.terminals.merchantName]: "Söluaðili",
    [StringResources.pages.terminals.terminalId]: "Númer útstöðvar",
    [StringResources.pages.terminals.dbaName]: "Nafn sölustaðar",
    [StringResources.pages.terminals.city]: "Staður",
    [StringResources.pages.terminals.country]: "Land",
    [StringResources.pages.terminals.mcc]: "MCC",
    [StringResources.pages.terminals.status]: "Staða",
    [StringResources.pages.terminals.partnerName]: "Nafn samstarfsaðila",
    [StringResources.pages.terminals.agreementNumber]: "Samningsnúmer",
    [StringResources.pages.terminals.ecomSolutionType]: "Ecom lausnargerð",
    [StringResources.pages.terminals.ecomTerminalIdentifier]: "Auðkenni útstöðvar",
    [StringResources.pages.employees.title]: "Notendur",
    [StringResources.pages.employees.permissions]: "Aðgangsheimildir",
    [StringResources.pages.employees.apiKey]: "API lykill",
    [StringResources.pages.employees.table.nameHeaderLabel]: "Nafn",
    [StringResources.pages.employees.table.usernameHeaderLabel]: "Tölvupóstur",
    [StringResources.pages.employees.table.statusHeaderLabel]: "Staða",
    [StringResources.pages.employees.table.employeeRoleLabel]: "Aðgangur",
    [StringResources.pages.employees.table.statusActiveLabel]: "Virkja",
    [StringResources.pages.employees.table.statusInactiveLabel]: "Óvirkja",
    [StringResources.pages.employees.table.contractsHeaderLabel]: "Samningur",
    [StringResources.pages.employees.create.title]: "Bæta við notanda",
    [StringResources.pages.employees.create.permissions]: "Aðgangsheimildir",
    [StringResources.pages.employees.create.permissionsPage]: "Síða",
    [StringResources.pages.employees.create.confirm]: "Bæta við notanda",
    [StringResources.pages.employees.create.buttonText]: "Bæta við notanda",
    [StringResources.pages.employees.create.close]: "Loka",
    [StringResources.pages.employees.create.formEmailInputLabel]: "Notandanafn",
    [StringResources.pages.employees.create.formContractInputLabel]: "Samningar",
    [StringResources.pages.employees.create.formButtonCaption]: "Búa til",
    [StringResources.pages.employees.create.formEmailValidationMsg]: "Sláðu inn virkt netfang",
    [StringResources.pages.employees.create.formUsernameAlreadyHasAccessValidationMsg]:
        "Notandi hefur nú þegar aðgang að þessum söluaðilasamningi",
    [StringResources.pages.employees.create.successMsg]: "Nýjum notanda hefur verið bætt við",

    [StringResources.pages.employees.edit.title]: "Breyta notanda",
    [StringResources.pages.employees.edit.permissions]: "Aðgangsheimildir",
    [StringResources.pages.employees.edit.permissionsPage]: "Síða",
    [StringResources.pages.employees.edit.confirm]: "Breyta",
    [StringResources.pages.employees.edit.deactivate]: "Óvirkja",
    [StringResources.pages.employees.edit.close]: "Loka",
    [StringResources.pages.employees.edit.formContractInputLabel]: "Samningar",
    [StringResources.pages.employees.edit.successMsg]: "Tókst að breyta notanda",
    [StringResources.pages.employees.edit.errorMsg]: "Villa við að breyta notanda",
    [StringResources.pages.employees.edit.errorApiKeyMsg]: "Villa við að sækja API lykil",
    [StringResources.pages.employees.edit.successDeactivateMsg]: "Tókst að slökkva á notanda",
    [StringResources.pages.employees.edit.formRoleLabel]: "Aðgangur",
    [StringResources.pages.employees.edit.formAdminLabel]: "Stjórnandi",
    [StringResources.pages.employees.edit.formUserLabel]: "Notandi",
    [StringResources.pages.employees.edit.contracts]: "Samningar",
    [StringResources.pages.employees.edit.formAdminEmployeeHasAccessToAllContracts]:
        "Stjórnandi hefur aðgangað öllum samningum.",
    [StringResources.pages.employees.edit.formAdminEmployeeHasAllPermissions]:
        "Stjórnandi hefur allar aðgangsheimildir",

    [StringResources.pages.employees.details.title]: "Upplýsingar",
    [StringResources.pages.employees.details.edit]: "Breyta",
    [StringResources.pages.employees.details.employeeId]: "Samningsnúmer",
    [StringResources.pages.employees.details.username]: "Notandanafn",
    [StringResources.pages.employees.details.name]: "Name",
    [StringResources.pages.employees.details.createdTime]: "Stofndagur",
    [StringResources.pages.employees.details.archivedTime]: "Aðgangur að samningi",
    [StringResources.pages.employees.details.verificationTime]: "Stofntími",
    [StringResources.pages.employees.details.status]: "Staða",
    [StringResources.pages.employees.details.lastLoginDate]: "Síðasta innskráning",
    [StringResources.pages.employees.details.contracts]: "Samningur",
    [StringResources.pages.employees.details.languageType]: "Tungumál",
    [StringResources.pages.employees.details.showApiKey]: "Sýna API lykilorð",

    [StringResources.pages.overview.title]: "Yfirlit",
    [StringResources.pages.overview.turnover.title]: "Velta",
    [StringResources.pages.overview.turnover.region]: "Svæði",
    [StringResources.pages.overview.turnover.numberOfTransactions]: "Færslufjöldi",
    [StringResources.pages.overview.turnover.numberOfTransactionsPercentage]: "Færslufjöldi % af heild",
    [StringResources.pages.overview.turnover.volumeOfTransactions]: "Færsluvelta",
    [StringResources.pages.overview.turnover.volumeOfTransactionsPercentage]: "Færsluvelta % af heild",
    [StringResources.pages.overview.turnover.total]: "Samtals",
    [StringResources.pages.overview.turnover.ecomContract]: "Ecom samningur",
    [StringResources.pages.overview.turnover.posContract]: "Posi",
    [StringResources.pages.overview.turnover.all]: "Allir",
    [StringResources.pages.overview.turnover.fetchData]: "Ná í gögn",
    [StringResources.pages.overview.turnover.dateFrom]: "Dagsetning frá",
    [StringResources.pages.overview.turnover.dateTo]: "Dagsetning til",
    [StringResources.pages.overview.turnover.error]: "Villa kom upp við að sækja gögn",
    [StringResources.pages.overview.turnover.contract]: "Samningur",

    [StringResources.pages.disputes.title]: "Frávik",
    [StringResources.pages.disputes.purchaseDate]: "Kaupdagur",
    [StringResources.pages.disputes.agreement]: "Samningur",
    [StringResources.pages.disputes.cardNumber]: "Kortanúmer",
    [StringResources.pages.disputes.registrationDate]: "Skráningardagur",
    [StringResources.pages.disputes.gross]: "Brúttó",
    [StringResources.pages.disputes.currency]: "Mynt",
    [StringResources.pages.disputes.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.pages.disputes.arn]: "Arn (tilvísunarnúmer færsluhirðis)",
    [StringResources.pages.disputes.uploadDispute]: "Hlaða inn skjölum",
    [StringResources.pages.disputes.comment]: "Athugasemd",
    [StringResources.pages.disputes.emailAddress]: "Netfang",
    [StringResources.pages.disputes.cardType]: "Kortategund",
    [StringResources.pages.disputes.transactionType]: "Tegund fráviks",
    [StringResources.pages.disputes.disputeType]: "Tegund ágreiningsmáls",
    [StringResources.pages.disputes.batchNumber]: "Bunkanúmer",
    [StringResources.pages.disputes.transactionNumber]: "Færslunúmer",
    [StringResources.pages.disputes.isChargeback]: "Er endurkrafa",
    [StringResources.pages.disputes.isFinancial]: "Er fjárhagsleg",
    [StringResources.pages.disputes.isRepresentment]: "Er svar við endurkröfu",
    [StringResources.pages.disputes.isReversal]: "Er afturköllun",
    [StringResources.pages.disputes.uploadDisputeSuccess]: "Ágreiningur var hlaðið upp",
    [StringResources.pages.disputes.uploadDisputeError]: "Villa við að hlaða upp andmælum",
    [StringResources.pages.disputes.dateFrom]: "Dagsetning frá",
    [StringResources.pages.disputes.dateTo]: "Dagsetning til",
    [StringResources.pages.disputes.emailInvalid]: "Netfang er ekki gilt",
    [StringResources.pages.disputes.confirmationEmailWillBeSent]: "Staðfestingartölvupóstur verður sendur á netfang:",
    [StringResources.pages.disputes.fileUploadErrorTitle]: "Upphleðsla skráa mistókst",
    [StringResources.pages.disputes.fileUploadErrorDefault]:
        "Eitthvað fór úrskeiðis. Vinsamlegast reyndu aftur eftir smá.",
    [StringResources.pages.disputes.submitLater]: "Ljúka síðar",
    [StringResources.pages.disputes.confirmationEmailWillBeSent]:
        'Með því að ýta á "Staðfesta vörn" sendast skjölin til skoðunar og verður því ekki hægt að bæta við skjölum eftir á. Staðfesting verður send á eftirfarandi netfang:',
    [StringResources.pages.disputes.fileUploadErrorTitle]: "Ekki tókst að senda inn skjal",
    [StringResources.pages.disputes.fileUploadErrorDefault]: "Aðgerð tókst ekki. Vinsamlegast reynið aftur.",
    [StringResources.pages.disputes.submitLater]: "Bíða með staðfestingu",
    [StringResources.pages.disputes.confirmSubmit]:
        'Ertu viss um að þú viljir staðfesta vörn fyrir þetta frávik? Með því að ýta á "Staðfesta vörn" sendast skjölin til skoðunar og verður því ekki hægt að bæta við skjölum eftir á',
    [StringResources.pages.disputes.confirmSubmitTitle]: "Staðfesta vörn?",
    [StringResources.pages.disputes.submit]: "Staðfesta vörn",
    [StringResources.pages.disputes.confirmRemoveTitle]: "Fjarlægja skjöl?",
    [StringResources.pages.disputes.confirmRemoveText]: "Öll skjöl verða fjarlægð.",
    [StringResources.pages.disputes.confirmRemoveButton]: "Fjarlægja skjöl",
    [StringResources.pages.disputes.removeDocumentsError]: "Ekki tókst að fjarlægja skölin",
    [StringResources.pages.disputes.removeDocumentsSuccess]: "Skjöl fjarlægð",
    [StringResources.pages.disputes.acceptDisputeSuccess]: "Vörn fyrir frávik var staðfest",
    [StringResources.pages.disputes.acceptDisputeError]: "Ekki tókst að staðfesta vörn á fráviki",
    [StringResources.pages.disputes.removeDocuments]: "Fjarlægja skjöl",
    [StringResources.pages.disputes.retry]: "Reyna aftur",
    [StringResources.pages.disputes.remove]: "Fjarlægja",
    [StringResources.pages.disputes.fileLimitations]:
        ".jpg, .jpeg og .tiff skrár allt að 10MB og .pdf skrár allt að 2MB eru studdar.",
    [StringResources.pages.disputes.fileSizeError]: "Skráin er of stór.",
    [StringResources.pages.disputes.commentRequired]: "Nauðsynlegt er að fylla þetta svæði",
    [StringResources.pages.disputes.emailRequired]: "Nauðsynlegt er að fylla þetta svæði",

    [StringResources.pages.disputes.details.title]: "Frávik",
    [StringResources.pages.disputes.details.edit]: "Senda gögn",
    [StringResources.pages.notFound.title]: "Síða finnst ekki",
    [StringResources.pages.notFound.description]:
        "Síðan sem þú ert að reyna að skoða er ekki til eða þú hefur ekki leyfi til að skoða hana.",
    [StringResources.pages.notFound.link]: "Til baka",
    [StringResources.pages.none.title]: "Ekkert",
    [StringResources.pages.settings.title]: "Stillingar",
    [StringResources.pages.settings.generalTab]: "Almennt",
    [StringResources.pages.settings.emailsAndNotificationsTab]: "Tilkynningar",
    [StringResources.pages.settings.changePasswordTab]: "Breyta lykilorði",
    [StringResources.pages.settings.accountAndSecurity.title]: "Aðgangs- og öryggisstillingar",
    [StringResources.pages.settings.accountAndSecurity.details]: "Upplýsingar",
    [StringResources.pages.settings.accountAndSecurity.changePasswordTitle]: "Breyta lykilorði",
    [StringResources.pages.settings.accountAndSecurity.username]: "Skráður inn sem:",
    [StringResources.pages.settings.general.defaultLanguage]: "Tungumál",
    [StringResources.pages.settings.changePassword.oldPassword]: "Eldra lykilorð",
    [StringResources.pages.settings.changePassword.newPassword]: "Nýtt lykilorð",
    [StringResources.pages.settings.changePassword.repeatNewPassword]: "Endurtaka nýtt lykilorð",
    [StringResources.pages.settings.changePassword.saveNewPassword]: "Vista nýtt lykilorð",
    [StringResources.pages.settings.changePassword.confirmNewPassword]: "Staðfestu nýtt lykilorð",
    [StringResources.pages.settings.changePassword.currentPassword]: "Núverandi lykilorð",
    [StringResources.pages.settings.emailsAndNotifications.statementsTitle]: "Yfirlit",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioTitle]:
        "Tilkynning er send með tölvupósti þegar nýtt yfirlit er gefið út. Þú færð einungis yfirlit fyrir þá samninga sem þú hefur aðgang að í gegnum þjónustuvef Straums.",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioPdfEmail]:
        "Tölvupóstur með yfirliti í viðhengi (pdf)",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioNotificationEmail]:
        "Tölvupóstur án viðhengis",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioNoEmail]:
        "Ég vil ekki fá tilkynningu með tölvupósti",
    [StringResources.pages.settings.emailsAndNotifications.radioChange.statementsRadioSuccess]:
        "Tilkynningar stillingar voru uppfærðar",
    [StringResources.pages.settings.emailsAndNotifications.radioChange.statementsRadioError]:
        "Villa við að uppfæra tilkynningar stillingar",

    [StringResources.pages.settings.emailsAndNotifications.radioChange.disputeRadioError]:
        "Tilkynningar stillingar voru uppfærðar",
    [StringResources.pages.settings.emailsAndNotifications.radioChange.disputeRadioSuccess]:
        "Villa við að uppfæra tilkynningar stillingar",
    [StringResources.pages.settings.emailsAndNotifications.disputeTitle]: "Endurkröfur",
    [StringResources.pages.settings.emailsAndNotifications.disputeRadioTitle]:
        "Tilkynning er send með tölvupósti þegar ný endurkrafa berst. Þú færð einungis tilkynningar um endurkröfur fyrir þá samninga sem þú hefur aðgang að í gegnum þjónustuvef Straums.",
    [StringResources.pages.settings.emailsAndNotifications.disputeRadioNotificationEmail]: "Tilkynning í tölvupósti",
    [StringResources.pages.settings.emailsAndNotifications.disputeRadioNoEmail]:
        "Ég vil ekki fá tilkynningu með tölvupósti",

    [StringResources.pages.terms.title]: "Notendaskilmálar",
    [StringResources.pages.terms.link]: "Sækja skilmála á pdf formi",
    [StringResources.pages.terms.conditions]: "Ég samþykki notendaskilmála Straums",
    [StringResources.pages.terms.back]: "Skrá út",
    [StringResources.pages.terms.acceptTerms]: "Halda áfram",
    [StringResources.pages.terms.selectMerchant]: "Skráðu þig inn sem",
    [StringResources.pages.terms.descriptionFirst]: "Nauðsynlegt er að samþykkja",
    [StringResources.pages.terms.descriptionLink]: "notendaskilmála Straums",
    [StringResources.pages.terms.descriptionSecond]: "(gilda frá 1. janúar 2023) til að nota þjónustuvefinn.",

    [StringResources.pages.terms.acceptTerms]: "Samþykkja skilmála",
    [StringResources.pages.terms.userMessageAcceptTerms]:
        "Til að halda áfram að nota þjónustuvefinn verður stjórnandi að skrá sig inn og samþykkja notkunarskilmála.",
    [StringResources.pages.terms.newTermsAvailable.first]: "Við höfum uppfært viðskiptaskilmálana. Ýtið ",
    [StringResources.pages.terms.newTermsAvailable.second]: "hér",
    [StringResources.pages.terms.newTermsAvailable.third]: " til að hlaða niður nýrri útgáfu.",

    [StringResources.pages.disputeNew.title]: "Frávik",
    [StringResources.pages.disputeNew.currency]: "Mynt",
    [StringResources.pages.disputeNew.filter]: "Sía",
    [StringResources.pages.disputeNew.columnChooser]: "Column chooser",
    [StringResources.pages.disputeNew.reasonCode]: "Ástæðukóði",
    [StringResources.pages.disputeNew.reasonCodeValue]: "Ástæðukóða gildi",
    [StringResources.pages.disputeNew.reasonCodeCategory]: "Ástæðukóðaflokki",
    [StringResources.pages.disputeNew.status]: "Staða",
    [StringResources.pages.disputeNew.settlementAmount]: "Uppgjörsupphæð",
    [StringResources.pages.disputeNew.transactionAmount]: "Viðskiptaupphæð",
    [StringResources.pages.disputeNew.update]: "Svarað",
    [StringResources.pages.disputeNew.date]: "Dagsetning",
    [StringResources.pages.disputeNew.type]: "Tegund",
    [StringResources.pages.disputeNew.caseNumber]: "Númer",
    [StringResources.pages.disputeNew.disputeType]: "Tegund",
    [StringResources.pages.disputeNew.dateFrom]: "Dagsetning frá",
    [StringResources.pages.disputeNew.dateTo]: "Dagsetning til",
    [StringResources.pages.disputeNew.arn]: "Arn",

    [StringResources.pages[PageTypeEnum.PayByLink].title]: "Greiðslutenglar",

    [StringResources.export.confirm]: "Sækja gögn",
    [StringResources.export.title]: "Sækja gögn",
    [StringResources.export.close]: "Hætta við",
    [StringResources.export.exportType]: "Útflutningstegund",
    [StringResources.export.exportTypeCSV]: "CSV",
    [StringResources.export.exportTypeXLSX]: "XLSX",
    [StringResources.export.exportTypePDF]: "PDF",
    [StringResources.export.exportColumn]: "Veldu Gögn",
    [StringResources.export.exportAllColumns]: "Allir dálkar ({{numberOfAvailableColumns}} dálkar)",
    [StringResources.export.exportVisibleColumns]: "Einungis sýnilegir dálkar ({{numberOfVisibleColumns}} dálkar)",
    [StringResources.export.exportVisibleColumn]: "Einungis sýnilegir dálkar ({{numberOfVisibleColumns}} dálkar)",
    [StringResources.export.exportSuccess]: "Niðurhal tókst",
    [StringResources.export.exportError]: "Niðurhal mistókst",
    [StringResources.export.exportToManyRows]:
        "Gögn innihalda yfir {{maxItems}} línur. Vinsamlegast breytið stillingum í Síu og reynið aftur.",
    [StringResources.rowSelection.clearAll]: "Hreinsa síu",
    [StringResources.rowSelection.selectedRows]: "Valdar {{rows}} af {{totalCount}}",

    [StringResources.input.passwordShowCaption]: "Sýna",
    [StringResources.input.passwordHideCaption]: "Fela",
    [StringResources.input.passwordStrengthBarLabel]: "Styrkur lykilorðs",
    [StringResources.input.passwordStrengthBad]: "Mjög veikt",
    [StringResources.input.passwordStrengthWeak]: "Veikt",
    [StringResources.input.passwordStrengthPassable]: "Miðlungs",
    [StringResources.input.passwordStrengthGood]: "Sterkt",
    [StringResources.input.passwordStrengthVeryGood]: "Mjög sterkt",
    [StringResources.input.add]: "Leita að",

    [StringResources.login.backActionLabel]: "Fara til baka",
    [StringResources.login.title]: "Þjónustuvefur",
    [StringResources.login.formTitle]: "Innskráning",
    [StringResources.login.formImpersonateTitle]: "Innskráning (Admin Impersonate Login)",
    [StringResources.login.formEmailInputLabel]: "Tölvupóstur",
    [StringResources.login.formEmailValidationMsg]: "Vinsamlegast sláðu inn virkt netfang",
    [StringResources.login.formPasswordInputLabel]: "Lykilorð",
    [StringResources.login.formButtonCaption]: "Skrá inn",
    [StringResources.login.forgotPasswordLink]: "Búin/nn að gleyma lykilorðinu?",
    [StringResources.login.loginFailed]: "Þú hefur slegið inn rangt notandanafn eða lykilorð",
    [StringResources.forgotPassword.title]: "Endursetja lykilorð?",
    [StringResources.forgotPassword.description]:
        "Vinsamlegast skráðu netfangið sem þú ert með skráð fyrir þjónustuvef Straums. Þú munt fá sendan tölvupóst með link til að stilla nýtt lykilorð.",
    [StringResources.forgotPassword.formEmailInputLabel]: "Netfang",
    [StringResources.forgotPassword.formEmailValidationMsg]: "Vinsamlegast sláðu inn virkt netfang",
    [StringResources.forgotPassword.formButtonCaption]: "Endursetja lykilorð",
    [StringResources.forgotPassword.backButtonCaption]: "Hætta við",
    [StringResources.forgotPassword.formSubmittedTitle]: "Tölvupóstur hefur verið sendur",
    [StringResources.forgotPassword.formSubmittedDescription]:
        "Við höfum sent þér tölvupóst á <strong>{{email}}</strong> sem inniheldur link sem þú þarft að ýta á til þess að geta útbúa nýtt lykilorð.",
    [StringResources.forgotPassword.formSubmittedBackButtonCaption]: "Fara til baka í innskráningu",
    [StringResources.resetPassword.title]: "Velja nýtt lykilorð",
    [StringResources.resetPassword.formPasswordInputLabel]: "Nýtt lykilorð",
    [StringResources.resetPassword.formPasswordValidationMsg]: "Lykilorðið verður að vera að lágmarki 8 stafir",
    [StringResources.resetPassword.formConfirmPasswordInputLabel]: "Staðfesta nýtt lykilorð",
    [StringResources.resetPassword.formConfirmPasswordValidationMsg]: "Lykilorð verður að vera það sama",
    [StringResources.resetPassword.formButtonCaption]: "Staðfesta",
    [StringResources.resetPassword.formSubmittedTitle]: "Lykilorði þínu hefur nú verið breytt",
    [StringResources.resetPassword.formSubmittedDescription]: "Núna getur þú skráð þig inn með nýja lykilorðinu.",
    [StringResources.resetPassword.formSubmittedBackButtonCaption]: "Fara til baka í innskráningu",
    [StringResources.confirmAccount.title]: "Stofna aðgang að þjónustuvef Straums",
    [StringResources.confirmAccount.subTitle]: "Veldur lykilorð til að klára að setja upp aðganginn þinn.",
    [StringResources.confirmAccount.formDisplayUsernameInputLabel]: "Netfang:",
    [StringResources.confirmAccount.formPasswordInputLabel]: "Veldu lykilorð",
    [StringResources.confirmAccount.formPasswordValidationMsg]: "Lykilorðið verður að vera að lágmarki 8 stafir",
    [StringResources.confirmAccount.formConfirmPasswordInputLabel]: "Staðfesta lykilorð",
    [StringResources.confirmAccount.formConfirmPasswordValidationMsg]: "Lykilorðin verða að vera það sama",
    [StringResources.confirmAccount.formButtonCaption]: "Áfram",
    [StringResources.confirmAccount.formSubmittedTitle]: "Lykilorði þínu hefur verið breytt",
    [StringResources.confirmAccount.formSubmittedDescription]: "Þú getur nú skráð þig inn.",
    [StringResources.confirmAccount.formSubmittedBackButtonCaption]: "Fara til baka í innskráningu",
    [StringResources.confirmAccount.contactServiceDesk]:
        "Ert þú að lenda í vandræðum með að búa til aðgang? Þú getur haft samband með því að senda okkur tölvupóst á <hyperLink>{{straumurEmail}}</hyperLink> eða hringt í okkur í síma <telLink>{{straumurPhone}}</telLink>.",
    [StringResources.multiselect.selected]: "Valið",
    [StringResources.multiselect.options]: "Valmöguleikar",
    [StringResources.multiselect.selectAll]: "Velja allt",
    [StringResources.multiselect.clearAll]: "Hreinsa síu",
    [StringResources.dashboard.totalSales]: "Sala mánaðarins",
    [StringResources.dashboard.totalSalesTooltip]: "Sala mánaðarins til dagsins í dag",
    [StringResources.dashboard.salesToday]: "Sala dagsins",
    [StringResources.dashboard.salesTodayTooltip]: "Heildarsala dagsins",
    [StringResources.dashboard.upcomingPayment]: "Til greiðslu næst",
    [StringResources.dashboard.upcomingPaymentTooltip]: "Til greiðslu á næsta greiðsludegi (með gjöldum)",
    [StringResources.dashboard.unpaidSettlementsAmount]: "Ógreidd staða",
    [StringResources.dashboard.unpaidSettlementsAmountToolTip]: "Summa allra ógreiddra uppgjöra",
    [StringResources.dashboard.numberOfSales]: "Fjöldi færslna",
    [StringResources.dashboard.numberOfSalesTooltip]: "Fjöldi færslna niður á dag",
    [StringResources.dashboard.grossSales]: "Heildarsala án gjalda",
    [StringResources.dashboard.grossSalesTooltip]: "af mánaðarlegu markmiði",
    [StringResources.dashboard.online]: "Vefverslun",
    [StringResources.dashboard.offline]: "Verslun",
    [StringResources.dashboard.pieChartName]: "Vefverslun/Verslun",
    [StringResources.dashboard.pieChartTooltip]: "Heildarsala á netinu miðað við heildarsölu í verslun",
    [StringResources.dashboard.salesByOutlet]: "Sala eftir sölustöðum",
    [StringResources.dashboard.salesByOutletTooltip]: "Heildarsala í þessum mánuði skipt eftir stöðum",
    [StringResources.dashboard.salesByOutletBarTooltip]: "Söluupphæð",
    [StringResources.dashboard.dailyAverage]: "Daglegt meðaltal",
    [StringResources.dashboard.noDailyAverage]: "Engar upplýsingar til staðar",
    [StringResources.dashboard.ofMonthlyTarget]: "af mánaðarlegu markmiði",
    [StringResources.dashboard.setSalesTarget]: "Skrá sölumarkmið",
    [StringResources.dashboard.scheduled]: "Áætlað",
    [StringResources.dashboard.day]: "Dagur",
    [StringResources.dashboard.noData]: "Engin gögn til að sýna",
    [StringResources.dashboard.filterByCurrencyPt1]: "Aðeins samningar í",
    [StringResources.dashboard.filterByCurrencyPt2]: "eru sýndir",
    [StringResources.dashboard.exportGraph]: "Hlaða niður gögnum",
    [StringResources.dashboard.dccCommission]: "DCC þóknun",
    [StringResources.dashboard.dccCommissionTooltip]: "Heildar DCC þóknun áunnin í mánuðinum og fjöldi DCC viðskipta",
    [StringResources.dashboard.dccCommissionTransactions]: "viðskipti",
    [StringResources.dashboard.january]: "Janúar",
    [StringResources.dashboard.february]: "Febrúar",
    [StringResources.dashboard.march]: "Mars",
    [StringResources.dashboard.april]: "Apríl",
    [StringResources.dashboard.may]: "Maí",
    [StringResources.dashboard.june]: "Júní",
    [StringResources.dashboard.july]: "Júlí",
    [StringResources.dashboard.august]: "Ágúst",
    [StringResources.dashboard.september]: "September",
    [StringResources.dashboard.october]: "Október",
    [StringResources.dashboard.november]: "Nóvember",
    [StringResources.dashboard.december]: "Desember",
    [StringResources.dashboard.average]: "Meðaltal",
    [StringResources.dashboard.outlet]: "Sölustöðum",
    [StringResources.dashboard.allInCurrency]: "Allir í",
    [StringResources.dashboard.contracts]: "Samningar",

    [StringResources.versionCheck.text]:
        "Ný útgáfa af vefnum var að detta í hús með allskonar nýjungum - til að uppfæra þarf einungis að smella ",
    [StringResources.versionCheck.button]: "hérna!",

    [StringResources.kyc.backActionLabel]: "Til baka á síðu",
    [StringResources.kyc.title]: "Áreiðanleikakönnun",
    [StringResources.kyc.documentTitle]: "Straumur - Áreiðanleikakönnun",
    [StringResources.kyc.formTitle]: "Innskrá með rafrænum skilríkjum",
    [StringResources.kyc.formPhoneNumberInputLabel]: "Símanúmer",
    [StringResources.kyc.formButtonCaption]: "Innskráning",
    [StringResources.kyc.somethingWentWrong]: "Eitthvað fór úrskeiðis. Vinsamlegast reyndu aftur eftir smá.",
    [StringResources.kycLegalEntity.backActionLabel]: "Til baka á síðu",
    [StringResources.kycLegalEntity.title]: "Veljið lögaðila",
    [StringResources.kycLegalEntity.subTitle]:
        "Þú getur valið að svara Áreiðanleikakönnun fyrir hönd eftirfarandi lögaðila:",
    [StringResources.kycLegalEntity.empty]: "Það eru engin fyrirtæki tengd þínum aðgang.",
    [StringResources.kycSurvey.backActionLabel]: "Til baka á síðu",
    [StringResources.kycSurvey.title]: "Áreiðanleikakönnun",
    [StringResources.kycSurvey.fieldError]: "Svara þarf þessari spurningu",
    [StringResources.kycSurvey.reviewAnswer]: "Vinsamlegast athugaðu svarið",
    [StringResources.kycSurvey.fetchQuestionarieError]: "Eitthvað fór úrskeiðis. Vinsamlegast reyndu aftur eftir smá.",
    [StringResources.kycSurvey.genericError]: "Það er villa í KYC spurningalistanum. Vinsamlegast yfirfarið svörin.",
    [StringResources.kycSurvey.changeButton]: "Breyta",
    [StringResources.kycSurvey.changeText]: "Þú ert að svara fyrir hönd ",
    [StringResources.kycSurvey.formButtonCaption]: "Senda svör",
    [StringResources.kycSurvey.beneficialOwner.fullName]: "Fullt nafn",
    [StringResources.kycSurvey.beneficialOwner.ssn]: "Kennitala",
    [StringResources.kycSurvey.beneficialOwner.shares]: "Eignarhlutfall",
    [StringResources.kycSurvey.beneficialOwner.phone]: "Sími",
    [StringResources.kycSurvey.beneficialOwner.addPerson]: "Bæta við",
    [StringResources.kycSurvey.legalBinder.fullName]: "Fullt nafn",
    [StringResources.kycSurvey.legalBinder.ssn]: "Kennitala",
    [StringResources.kycSurvey.legalBinder.position]: "Starfstitill",
    [StringResources.kycSurvey.legalBinder.phone]: "Sími",
    [StringResources.kycSurvey.legalBinder.addPerson]: "Bæta við",
    [StringResources.kycSurvey.legalBinder.positions.boardMember]: "Stjórnarmaður",
    [StringResources.kycSurvey.legalBinder.positions.ceo]: "Forstjóri",
    [StringResources.kycSurvey.legalBinder.positions.chairmanOfTheBoard]: "Stjórnarformaður",
    [StringResources.kycSurvey.legalBinder.positions.other]: "Annað",
    [StringResources.kycSurvey.legalBinder.positions.powerOfAttorney]: "Prókúruhafi",
    [StringResources.kycSurvey.legalBinder.positions.comanager]: "Meðstjórnandi",
    [StringResources.kycSuccess.backActionLabel]: "Til baka á síðu",
    [StringResources.kycSuccess.title]: "Takk fyrir!",
    [StringResources.kycSuccess.subTitle]:
        "Svörin þín hafa verið móttekin. Starfsfólk Straums munu svara þér eins fljótt og auðið er.",
    [StringResources.kycSuccess.buttonCaption]: "Til baka á straumur.is",

    [StringResources.activityTracker.somethingWentWrong]: "Eitthvað fór úrskeiðis. Vinsamlegast skráðu þig aftur inn",
    [StringResources.activityTracker.loggedOutInactivtiy]:
        "Þú hefur verið skráð/ur út af vefnum vegna aðgerðaleysis. Vinsamlegast skráðu þig aftur inn.",

    [StringResources.networkStatusIndicator.online]: "Tenging virk!",
    [StringResources.networkStatusIndicator.offline]:
        "Nettenging þín er ekki virk. Vinsamlegast athugað nettenginguna þína.",

    [StringResources.pages.terminals.title]: "Útstöðvar",
    [StringResources.pages.terminals.merchantName]: "Söluaðili",
    [StringResources.pages.terminals.terminalId]: "Númer útstöðvar",
    [StringResources.pages.terminals.dbaName]: "Nafn sölustaðar",
    [StringResources.pages.terminals.city]: "Staður",
    [StringResources.pages.terminals.country]: "Land",
    [StringResources.pages.terminals.mcc]: "MCC",
    [StringResources.pages.terminals.status]: "Staða",
    [StringResources.pages.terminals.partnerName]: "Nafn samstarfsaðila",
    [StringResources.pages.terminals.agreementNumber]: "Samningsnúmer",
    [StringResources.pages.terminals.generalInfo]: "Almennar upplýsingar",
    [StringResources.pages.terminals.terminalFunctionalities]: "Posa aðgerðir",
    [StringResources.pages.terminals.dba]: "Sölustaðar",
    [StringResources.pages.terminals.expectedStoreTurnoverInIsk]: "Áætluð heildarvelta í ISK",
    [StringResources.pages.terminals.create.newAgreement]: "Nýr samningur",
    [StringResources.pages.terminals.create.newStore]: "Ný verslun",
    [StringResources.pages.terminals.create.requestTerminal]: "Nýr posi",
    [StringResources.pages.terminals.create.successMsg]: "Nýr posi stofnaður",
    [StringResources.pages.terminals.create.title]: "Nýr posi",
    [StringResources.pages.terminals.create.store]: "Ný verslun",
    [StringResources.pages.terminals.create.typeOfBusiness]: "Tegund viðskipta",
    [StringResources.pages.terminals.create.printer]: "Prentari",
    [StringResources.pages.terminals.create.noPrinter]: "Án prentara",
    [StringResources.pages.terminals.create.ecrIntegrated]: "Tengdur kassakerfi",
    [StringResources.pages.terminals.create.standalone]: "Þráðlaus",
    [StringResources.pages.terminals.create.wifi]: "Wifi",
    [StringResources.pages.terminals.create.fourG]: "4G",
    [StringResources.pages.terminals.create.quantityOfTerminals]: "Fjöldi posa",
    [StringResources.pages.terminals.create.ecrSystem]: "Kassakerfi",
    [StringResources.pages.terminals.create.name]: "Nafn",
    [StringResources.pages.terminals.create.streetAndNumber]: "Heimilisfang",
    [StringResources.pages.terminals.create.city]: "Staður",
    [StringResources.pages.terminals.create.postalCode]: "Póstnúmer",
    [StringResources.pages.terminals.create.country]: "Land",
    [StringResources.pages.terminals.create.averageDeliveryInDays]: "Áætlaður afhendingartími vöru í dögum",
    [StringResources.pages.terminals.create.annualVolumeCardPresent]: "Árleg velta í posa",
    [StringResources.pages.terminals.create.averageMonthlyVolume]: "Meðal mánaðarvelta",
    [StringResources.pages.terminals.create.maxTransactionAmount]: "Hámarksupphæð færslu",
    [StringResources.pages.terminals.create.anualVolumeMoto]: "Árleg velt í símgreiðslum",
    [StringResources.pages.terminals.create.averageTransactionAmount]: "Meðaupphæð færslu",
    [StringResources.pages.terminals.create.annualVolumeTotal]: "Heildar ársvelta",
    [StringResources.pages.terminals.create.averageMonthlyNumberOfTransactions]: "Meðal fjöldi færslna í mánuði",
    [StringResources.pages.terminals.create.newMccLabel]: "Bæta við...",
    [StringResources.pages.terminals.create.delivery]: "Afhending",
    [StringResources.pages.terminals.create.deliveryType]: "Afhendingarmáti",
    [StringResources.pages.terminals.create.terminalDeliveryDate]: "Afhendingardagur flugstöðvar",
    [StringResources.pages.terminals.create.comment]: "Athugasemd",

    [StringResources.pages.terminals.error.quantityOfTerminalsMustBeLarger]: "Fjöldi posa þarf að vera meira en 0",
    [StringResources.pages.terminals.error.quantityOfTerminalsRequired]: "Velja posa er skilyrði",
    [StringResources.pages.terminals.error.ecrSystemRequired]: "Velja kassakerfi er skilyrði",
    [StringResources.pages.terminals.error.agreementNumberRequired]: "Samningsnúmer er skilyrði",
    [StringResources.pages.terminals.error.storeIsRequired]: "Verslun er skilyrði",
    [StringResources.pages.terminals.error.typeOfBusinessIsRequired]: "Tegund viðskipta er skilyrði",
    [StringResources.pages.terminals.error.nameIsRequired]: "Nafn er skilyrði",
    [StringResources.pages.terminals.error.streetAndNumberIsRequired]: "Heimilisfang er skilyrði",
    [StringResources.pages.terminals.error.cityIsRequired]: "Borg er skilyrði",
    [StringResources.pages.terminals.error.postalCodeIsRequired]: "Póstnúmer er skilyrði",
    [StringResources.pages.terminals.error.countryIsRequired]: "Land er skilyrði",
    [StringResources.pages.terminals.error.averageDeliveryInDaysIsRequired]: "Meðal afhendingartími vöru er skilyrði",
    [StringResources.pages.terminals.error.annualVolumeCardPresentIsRequired]: "Árleg velta í posa er skilyrði",
    [StringResources.pages.terminals.error.averageMonthlyVolumeIsRequired]: "Meðal mánaðarvelta er skilyrði",
    [StringResources.pages.terminals.error.maxTransactionAmountIsRequired]: "Hámarksupphæð færslu er skilyrði",
    [StringResources.pages.terminals.error.annualVolumeMotoIsRequired]: "Árleg velta í símgreiðslum er skilyrði",
    [StringResources.pages.terminals.error.averageTransactionAmountIsRequired]: "Meðalupphæð færslu er skilyrði",
    [StringResources.pages.terminals.error.annualVolumeTotalIsRequired]: "Áleg velta er skilyrði",
    [StringResources.pages.terminals.error.averageMonthlyNumberOfTransactionsIsRequired]:
        "Meðalfjöldi færslna í mánuði er skilyrði",
    [StringResources.pages.terminals.error.fetchError]: "Villa þegar gögn eru sótt",
    [StringResources.pages.terminals.error.submitError]: "Villa í innsendingu",

    [StringResources.pages[PageTypeEnum.ApiKeys].title]: "Api lyklar",
    [StringResources.pages[PageTypeEnum.ApiKeys].keyName]: "Nafn lykils",
    [StringResources.pages[PageTypeEnum.ApiKeys].apiKey]: "Api lykill",
    [StringResources.pages[PageTypeEnum.ApiKeys].status]: "Staða",
    [StringResources.pages[PageTypeEnum.ApiKeys].contracts]: "Samningar",
    [StringResources.pages[PageTypeEnum.ApiKeys].createdTime]: "Stofnaður þann",
    [StringResources.pages[PageTypeEnum.ApiKeys].userCreated]: "Stofnaður af",
    [StringResources.pages[PageTypeEnum.ApiKeys].userDisabled]: "Afvirkaður af",
    [StringResources.pages[PageTypeEnum.ApiKeys].hasCopyExpired]: "Tími til að afrita liðinn",
    [StringResources.pages[PageTypeEnum.ApiKeys].isSubscribedToAllContracts]: "Allir samningar skráðir",
    [StringResources.pages[PageTypeEnum.ApiKeys].validTo]: "Virkur til",
    [StringResources.pages[PageTypeEnum.ApiKeys].dateDisabled]: "Afvirkjaður þann",
    [StringResources.pages[PageTypeEnum.ApiKeys].edit]: "Breyta",
    [StringResources.pages[PageTypeEnum.ApiKeys].disable]: "Afvirkja",
    [StringResources.pages[PageTypeEnum.ApiKeys].copyToClipboardExpired]:
        "24 tímar sem hægt er að afrita lykil er liðinn",
    [StringResources.pages[PageTypeEnum.ApiKeys].copyToClipboard]: "Afrita",
    [StringResources.pages[PageTypeEnum.ApiKeys].generateNewKey]: "Stofna nýjan lykil",
    [StringResources.pages[PageTypeEnum.ApiKeys].generateNewKeySuccess]: "Nýr API lykill stofnaður",
    [StringResources.pages[PageTypeEnum.ApiKeys].generateNewKeyError]: "Villa kom upp við að stofna nýjan API lykil",
    [StringResources.pages[PageTypeEnum.ApiKeys].editKeySuccess]: "Breyting á Api lykli tókst",
    [StringResources.pages[PageTypeEnum.ApiKeys].editKeyError]: "Villa kom upp við breytingu á API lykli",
    [StringResources.pages[PageTypeEnum.ApiKeys].disabledKeySuccess]: "Afvirkja API lykil tókst",
    [StringResources.pages[PageTypeEnum.ApiKeys].disabledKeyError]: "Villa kom upp þegar API lykill var afvirkjaður",
    [StringResources.pages[PageTypeEnum.ApiKeys].generateNewKeyBannerMessage]:
        "”Afrita” virknin verður í boði í 24 klst eftir að lykill hefur verið stofnaður. Eftir þann tíma verður “Afrita” óvirkt og stofna þarf þá nýjan lykil ef þessi týnist.",
    [StringResources.pages[PageTypeEnum.ApiKeys].all]: "Allt",
    [StringResources.pages[PageTypeEnum.ApiKeys].apiKeyType]: "Api lykla tegund",

    [StringResources.reports[ReportType.Agreements].title]: "Samningar",
    [StringResources.reports[ReportType.Authorizations].title]: "Heimildir",
    [StringResources.reports[ReportType.Batches].title]: "Bunkar",
    [StringResources.reports[ReportType.DailyBalance].title]: "Daglegur stöðugleiki",
    [StringResources.reports[ReportType.ElectronicDocuments].title]: "Rafræn skjöl",
    [StringResources.reports[ReportType.Payments].title]: "Greiðslur",
    [StringResources.reports[ReportType.Settlements].title]: "Uppgjör",
    [StringResources.reports[ReportType.Transactions].title]: "Færslur",
    [StringResources.reports[ReportType.Terminals].title]: "Útstöðvar",
    [StringResources.reports[ReportType.Employees].title]: "Starfsmenn",
    [StringResources.reports[ReportType.DisputeNew].title]: "Frávik",
    [StringResources.reports[ReportType.Overview].title]: "Yfirlit",
    [StringResources.reports[ReportType.Disputes].title]: "Frávik",

    [StringResources.reports[ReportType.PayByLink].title]: "Greiðslutenglar",
    [StringResources.reports[ReportType.PayByLink].createPaymentLink]: "Stofna greiðslutengil",
    [StringResources.reports[ReportType.PayByLink].paymentLink]: "Greiðslutengill",
    [StringResources.reports[ReportType.PayByLink].amount]: "Upphæð",
    [StringResources.reports[ReportType.PayByLink].amountFrom]: "Upphæð frá",
    [StringResources.reports[ReportType.PayByLink].amountTo]: "Upphæð til",
    [StringResources.reports[ReportType.PayByLink].description]: "Lýsing",
    [StringResources.reports[ReportType.PayByLink].dateCreated]: "Stofnaður",
    [StringResources.reports[ReportType.PayByLink].dateCreatedFrom]: "Stofnað frá",
    [StringResources.reports[ReportType.PayByLink].dateCreatedTo]: "Stofnað til",
    [StringResources.reports[ReportType.PayByLink].currencyId]: "Mynt",
    [StringResources.reports[ReportType.PayByLink].status]: "Staða",
    [StringResources.reports[ReportType.PayByLink].visits]: "Opnanir",
    [StringResources.reports[ReportType.PayByLink].sales]: "Sölur",
    [StringResources.reports[ReportType.PayByLink].createdBy]: "Stofnaður af",
    [StringResources.reports[ReportType.PayByLink].expires]: "Gildir til",
    [StringResources.reports[ReportType.PayByLink].copyToClipboard]: "Afrita greiðsluhlekk",
    [StringResources.reports[ReportType.PayByLink].update]: "Breyta",
    [StringResources.reports[ReportType.PayByLink].copy]: "Afrita",
    [StringResources.reports[ReportType.PayByLink].disable]: "Loka",
    [StringResources.reports[ReportType.PayByLink].disableLinkSuccessMessage]: "Greiðslutengill er núna óvirkur",
    [StringResources.reports[ReportType.PayByLink].disableLinkErrorMessage]: "Ekki tókst að afvirkja greiðslutengil",
    [StringResources.reports[ReportType.PayByLink].updateLinkErrorMessage]: "Ekki tókst að uppfæra greiðslutengilinn",
    [StringResources.reports[ReportType.PayByLink].updateLinkSuccessMessage]:
        "Greiðslutengill uppfærður með góðum árangri",
    [StringResources.reports[ReportType.PayByLink].actions]: "Aðgerðir",

    [StringResources.reports[ReportType.PayByLinkTransactions].title]: "Sölur",
    [StringResources.reports[ReportType.PayByLinkTransactions].date]: "Dagsetning",
    [StringResources.reports[ReportType.PayByLinkTransactions].amount]: "Upphæð",
    [StringResources.reports[ReportType.PayByLinkTransactions].paymentLink]: "Greiðslutengill",
    [StringResources.reports[ReportType.PayByLinkTransactions].refundAmount]: "Endurgreitt",
    [StringResources.reports[ReportType.PayByLinkTransactions].currency]: "Mynt",
    [StringResources.reports[ReportType.PayByLinkTransactions].referenceNumber]: "Tilvísun",
    [StringResources.reports[ReportType.PayByLinkTransactions].transactionNumber]: "Færslunúmer",
    [StringResources.reports[ReportType.PayByLinkTransactions].productDescription]: "Vörulýsing",
    [StringResources.reports[ReportType.PayByLinkTransactions].cardNumber]: "Kortanúmer",
    [StringResources.reports[ReportType.PayByLinkTransactions].cardType]: "Kortategund",
    [StringResources.reports[ReportType.PayByLinkTransactions].agreementNumber]: "Samningsnúmer",
    [StringResources.reports[ReportType.PayByLinkTransactions].shopperName]: "Nafn korthafa",
    [StringResources.reports[ReportType.PayByLinkTransactions].shopperEmail]: "Netfang korthafa",
    [StringResources.reports[ReportType.PayByLinkTransactions].shopperSSN]: "Kennitala korthafa",
    [StringResources.reports[ReportType.PayByLinkTransactions].postalCode]: "Póstnúmer",
    [StringResources.reports[ReportType.PayByLinkTransactions].address]: "Heimilisfang",
    [StringResources.reports[ReportType.PayByLinkTransactions].city]: "Borg",
    [StringResources.reports[ReportType.PayByLinkTransactions].country]: "Land",
    [StringResources.reports[ReportType.PayByLinkTransactions].phoneNumber]: "Símanúmer",
    [StringResources.reports[ReportType.PayByLinkTransactions].comments]: "Athugasemdir",
    [StringResources.reports[ReportType.PayByLinkTransactions].nameOfShopper]: "Nafn korthafa",
    [StringResources.reports[ReportType.PayByLinkTransactions].ssn]: "Kennitala",
    [StringResources.reports[ReportType.PayByLinkTransactions].email]: "Netfang",
    [StringResources.reports[ReportType.PayByLinkTransactions].amountFrom]: "Upphæð frá",
    [StringResources.reports[ReportType.PayByLinkTransactions].amountTo]: "Upphæð til",
    [StringResources.reports[ReportType.PayByLinkTransactions].dateCreated]: "Stofnaður",
    [StringResources.reports[ReportType.PayByLinkTransactions].copyToClipboard]: "Afrita greiðsluhlekk",
    [StringResources.reports[ReportType.PayByLinkTransactions].viewAuthorization]: "Skoða Heimild",
    [StringResources.reports[ReportType.PayByLinkTransactions].viewTransaction]: "Skoða Færslu",

    [StringResources.enums.terminalRequestDeliveryType[TerminalRequestDeliveryType.Pickup]]: "Sækja",
    [StringResources.enums.terminalRequestDeliveryType[TerminalRequestDeliveryType.Send]]: "Senda",
    [StringResources.enums.language[SupportedLanguageEnum.English]]: "Enska",
    [StringResources.enums.language[SupportedLanguageEnum.Icelandic]]: "Íslenska",

    [StringResources.pages.ecomThemes.title]: "Þemu",
    [StringResources.pages.ecomThemes.create.addNewTheme]: "Bæta við þema",
    [StringResources.pages.ecomThemes.create.successMsg]: "Þema búið til",
    [StringResources.pages.ecomThemes.create.title]: "Bæta við þema",
    [StringResources.pages.ecomThemes.create.themeKey]: "Þema lykill",
    [StringResources.pages.ecomThemes.create.name]: "Nafn",
    [StringResources.pages.ecomThemes.create.description]: "Lýsing",
    [StringResources.pages.ecomThemes.create.colorHex]: "Merki litur",
    [StringResources.pages.ecomThemes.create.logo]: "Merki",
    [StringResources.pages.ecomThemes.create.setAsDefaultTheme]: "Setja þema sem sjálfgefið",
    [StringResources.pages.ecomThemes.create.generalThemeInfo]: "Þema upplýsingar",
    [StringResources.pages.ecomThemes.create.design]: "Litur",
    [StringResources.pages.ecomThemes.create.upload]: "Hlaða inn",
    [StringResources.pages.ecomThemes.create.create]: "Búa til",
    [StringResources.pages.ecomThemes.create.default]: "Sjálfgefið",
    [StringResources.pages.ecomThemes.create.remove]: "Fjarlægja",
    [StringResources.pages.ecomThemes.create.maxWidth]: "Hámarksbreidd",
    [StringResources.pages.ecomThemes.create.maxHeight]: "Hámarkshæð",
    [StringResources.pages.ecomThemes.create.maxFileSize]: "Hámarksstærð",
    [StringResources.pages.ecomThemes.create.error.invalidType]:
        "Aðeins er tekið við skrám af tegundinni JPG, PNG eða SVG.",
    [StringResources.pages.ecomThemes.create.error.wrongDimensions]:
        "Hlutföll myndar geta að hámarki orðið 280 px að breidd og 64px að hæð",
    [StringResources.pages.ecomThemes.create.error.wrongSize]: "Stærð skráar getur ekki farið yfir 50KB",
    [StringResources.pages.ecomThemes.edit.editTheme]: "Breyta þema",
    [StringResources.pages.ecomThemes.edit.save]: "Vista",
    [StringResources.pages.ecomThemes.edit.copyToClipboard]: "Afritað á klippiborð",
    [StringResources.pages.ecomThemes.edit.successMsg]: "Tókst að breyta þema",
    [StringResources.pages.ecomThemes.delete.deleteTitle]: "Eyða þema",
    [StringResources.pages.ecomThemes.delete.deleteMessage]: "Ertu viss um að þú viljir eyða þema?",
    [StringResources.pages.ecomThemes.delete.successMsg]: "Þema hefur verið eytt",
    [StringResources.pages.ecomThemes.error.nameIsRequired]: "Nafn er áskilið",
    [StringResources.pages.ecomThemes.error.colorIsRequired]: "Merkjalitur er nauðsynlegur",

    [StringResources.pages[PageTypeEnum.Webhooks].bannerTitle]: "Mikilvægt",
    [StringResources.pages[PageTypeEnum.Webhooks].bannerFirstPharagraph]:
        "Þú munt aðeins fá webhook köll fyrir þá posa sem voru afhentir eftir fyrsta október 2024. Ef þú vilt uppfæra og fá nýjustu posana sem eru í boði þá vinsamlegast hafið samband við straumur@straumur.is ",
    [StringResources.pages[PageTypeEnum.Webhooks].bannerSecondPharagraph]:
        "Samþykkja þarf webhooks með því að senda svar með HTTP 200 stöðu kóða",
    [StringResources.pages[PageTypeEnum.Webhooks].bannerThirdPharagraph]:
        "Þegar búinn er til webhook, þá þarf þinn endapunktur að senda samþykki með því að svara með HTTP 200 stöðu kóða.",
    [StringResources.pages[PageTypeEnum.Webhooks].title]: "Webhooks",
    [StringResources.pages[PageTypeEnum.Webhooks].webhookName]: "Nafn",
    [StringResources.pages[PageTypeEnum.Webhooks].webhookUrl]: "Vefslóð",
    [StringResources.pages[PageTypeEnum.Webhooks].webhookApiKey]: "Webhook API lykill",
    [StringResources.pages[PageTypeEnum.Webhooks].hmac]: "HMAC",
    [StringResources.pages[PageTypeEnum.Webhooks].status]: "Staða",
    [StringResources.pages[PageTypeEnum.Webhooks].contractNumbers]: "Samningar",
    [StringResources.pages[PageTypeEnum.Webhooks].createdDate]: "Stofnaður þann",
    [StringResources.pages[PageTypeEnum.Webhooks].userCreated]: "Stofnaður af",
    [StringResources.pages[PageTypeEnum.Webhooks].userDisabled]: "Afvirkjaður af",
    [StringResources.pages[PageTypeEnum.Webhooks].disabledDate]: "Afvirkjaður þann",
    [StringResources.pages[PageTypeEnum.Webhooks].isSubscribedToAllContracts]: "Er tengdur öllum samningum",
    [StringResources.pages[PageTypeEnum.Webhooks].copyToClipboardExpired]: "24 klst frestur til að afrita er liðinn",
    [StringResources.pages[PageTypeEnum.Webhooks].copyToClipboard]: "Afrita",
    [StringResources.pages[PageTypeEnum.Webhooks].edit]: "Breyta",
    [StringResources.pages[PageTypeEnum.Webhooks].disable]: "Afvirkja",
    [StringResources.pages[PageTypeEnum.Webhooks].createWebhook]: "Stofna nýjan Webhook",
    [StringResources.pages[PageTypeEnum.Webhooks].createWebhookSuccess]: "Nýr Webhook var stofnaður",
    [StringResources.pages[PageTypeEnum.Webhooks].createWebhookError]: "Ekki tókst að stofna Webhook",
    [StringResources.pages[PageTypeEnum.Webhooks].editWebhookSuccess]: "Webhook var breytt",
    [StringResources.pages[PageTypeEnum.Webhooks].editWebhookError]: "Ekki tókst að breyta Webhook",
    [StringResources.pages[PageTypeEnum.Webhooks].disabledWebhoookSuccess]: "Webhook var afvirkjaður",
    [StringResources.pages[PageTypeEnum.Webhooks].disabledWebhookError]: "Ekki tókst að afvirkja Webhook",
    [StringResources.pages[PageTypeEnum.Webhooks].modalFirstPharagraph]:
        "Vinsamlegast gangið úr skugga um að vefslóð sé algjör og gild. Hún ætti að byrja á 'https://'",
    [StringResources.pages[PageTypeEnum.Webhooks].modalSecondPharagraph]:
        "Hægt er að afrita nýja webhook API lykilinn næstu 24 klukkustundirnar. Eftir þann frest verður ekki hægt að afrita.",
    [StringResources.pages[PageTypeEnum.Webhooks].errors.webhookNameRequired]: "Nafn á Webhook vantar",
    [StringResources.pages[PageTypeEnum.Webhooks].errors.webhookUrlRequired]: "Slóð fyrir Webhook vantar",
    [StringResources.pages[PageTypeEnum.Webhooks].errors.incorrectUrl]:
        "Vefslóð er ekki gild - hún verður að vera algjör og byrja á https://",
};
