import classNames from "classnames";
import "./baseButton.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface IBaseButtonProps {
    text?: string;
    toolTip?: string;
    handleClick?: () => void;
    type?: "button" | "submit";
    styleType: "solid" | "text" | "line";
    size?: "medium" | "small" | "full" | "very-small";
    disabled?: boolean;
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
    danger?: boolean;
    className?: string;
}

const BaseButton = ({
    text,
    handleClick,
    toolTip,
    disabled,
    type = "button",
    styleType,
    size = "medium",
    leftIcon,
    rightIcon,
    danger,
    className,
}: IBaseButtonProps) => {
    const buttonTypeClass = `type--${styleType}`;
    const buttonSizeClass = `size--${size}`;

    const Button = (
        <button
            type={type}
            disabled={disabled}
            className={classNames("base-button", buttonTypeClass, buttonSizeClass, className, {
                "--disabled": disabled,
                "--danger": danger,
                "left-icon": leftIcon,
                "right-icon": rightIcon,
            })}
            onClick={handleClick}
        >
            {leftIcon && leftIcon}
            {text && text}
            {rightIcon && rightIcon}
        </button>
    );

    return (
        <div>
            {toolTip ? (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>{toolTip}</Tooltip>}
                >
                    <div>{Button}</div>
                </OverlayTrigger>
            ) : (
                <div>{Button}</div>
            )}
        </div>
    );
};

export default BaseButton;
